import * as zod from 'zod';

/**
 * This file is a bit of tech debt.
 * Ideally types don't call into registries, these types should be refactored to
 * expose the minimal common interface between all devices, etc.
 */

import { DeviceCommandsRegistry } from '../registry/deviceCommands';
import { DeviceConfigurationRegistry } from '../registry/deviceConfiguration';
import {
  DeviceStateRegistry,
  DynamicBaseStateRegistry,
} from '../registry/deviceState';

const [command0, command1, ...commands] = DeviceCommandsRegistry;
const [config0, config1, ...configs] = DeviceConfigurationRegistry;
const [state0, state1, ...states] = DeviceStateRegistry;

// need to ensure there are two elements, then an array of elements
// so that zod doesn't complain
export const DeviceCommand = zod.discriminatedUnion('kind', [
  command0,
  command1,
  ...commands,
]);

export const DeviceConfiguration = zod.discriminatedUnion('kind', [
  config0,
  config1,
  ...configs,
]);

// right now only one value, adding an odd type constraint to make sure
// we don't accidentally add another value and drop. If you add one, update
// this to be a union type like the others
export const DynamicBaseState = (
  DynamicBaseStateRegistry as [(typeof DynamicBaseStateRegistry)[0]]
)[0];

export const DeviceState = zod.union([state0, state1, ...states]);

export type DeviceState = zod.infer<typeof DeviceState>;
export type DeviceCommand = zod.infer<typeof DeviceCommand>;
export type DeviceConfiguration = zod.infer<typeof DeviceConfiguration>;
export type DynamicBaseState = zod.infer<typeof DynamicBaseState>;

// typecheck: this ensures all Configuration types have a non-optional `name` property.
// ts will error if `name` is optional or missing:
// "Type 'string | undefined' is not assignable to type 'string'. ts(2322)"
((name: DeviceConfiguration['name']): string => name)('');

export type DeviceKind = DeviceConfiguration['kind'];

export type DeviceFamily =
  | Extract<DeviceConfiguration, { family: unknown }>['family']
  | null;
