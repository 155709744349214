import type { DynamicBaseState } from '@sb/integrations/types';
import type { RoutineRunnerState } from '@sb/routine-runner';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

function getDynamicBaseState(
  state: RoutineRunnerState | null,
): DynamicBaseState | null {
  return state ? state.kinematicState.dynamicBaseState : null;
}

export default function useRobotDynamicBaseState(
  args: UseRoutineRunnerHandleArguments,
): DynamicBaseState | null {
  return useRobotState(args, getDynamicBaseState);
}
