import { asPaginated } from '@sb/feathers-types';
import { ExternalControlSettings } from '@sb/routine-runner';

import { getExternalControlSettingsService } from './getExternalControlSettingsService';

export async function getExternalControlSettings(
  robotID: string,
): Promise<ExternalControlSettings> {
  const response = await getExternalControlSettingsService().find({
    query: { robotID, $limit: 1 },
  });

  const data = asPaginated(response).data[0];

  const settings = ExternalControlSettings.parse(data ?? {});

  return settings;
}
