import { ModbusFunctionCode } from '../../utils/modbus/constants';

import type { ModbusTCPRegisterData } from './types';

export const ONROBOT_TCP_PORT_DEFAULT = 502;

export const TCP_CONNECTION_TIMEOUT_MS_DEFAULT = 10_000;

export const MODBUS_TCP_SERVER_NAME_DEFAULT = 'Modbus TCP Server';

export const MODBUS_TCP_SERVER_REGISTER_FORMAT_DEFAULT = 'UInt16BE';

export const MODBUS_TCP_SERVER_REGISTER_DEFAULT: ModbusTCPRegisterData = {
  id: '',
  name: '',
  type: 'holdingRegister',
  offset: 0,
  format: MODBUS_TCP_SERVER_REGISTER_FORMAT_DEFAULT,
};

export const modbusCodeList = [
  {
    name: 'Read Single Register',
    code: ModbusFunctionCode.Read,
  },
  {
    name: 'Write Single Register',
    code: ModbusFunctionCode.Write,
  },
];

// TODO: APPS-4523 Add support for batch requests, currently hardcoding to single value request
export const MODBUS_SINGLE_REQUEST_INDEX_DEFAULT = 0;
