import * as zod from 'zod';

import {
  type ModbusTCPServerReadRequest,
  ModbusTCPServerRequest,
  type ModbusTCPServerWriteRequest,
} from '@sb/integrations/implementations/ModbusTCPServer/command';
import { ModbusFunctionCode } from '@sb/integrations/utils/modbus/constants';

import { MODBUS_TCP_SERVER_REGISTER_DEFAULT } from '../constants';

export const ModbusTCPServerStepArguments = zod.object({
  kind: zod.literal('ModbusTCPServer'),
  equipmentId: zod.string(),
  registerId: zod.string(),
  request: ModbusTCPServerRequest,
});

export type ModbusTCPServerStepArguments = zod.infer<
  typeof ModbusTCPServerStepArguments
>;

export const MODBUS_TCP_READ_REGISTER_REQUEST_DEFAULT: ModbusTCPServerReadRequest =
  {
    kind: ModbusFunctionCode.Read,
    variableIDList: [''],
    register: MODBUS_TCP_SERVER_REGISTER_DEFAULT,
  };

export const MODBUS_TCP_WRITE_REGISTER_REQUEST_DEFAULT: ModbusTCPServerWriteRequest =
  {
    kind: ModbusFunctionCode.Write,
    valueList: [0],
    register: MODBUS_TCP_SERVER_REGISTER_DEFAULT,
  };

export const MODBUS_TCP_STEP_ARGS_DEFAULT: ModbusTCPServerStepArguments = {
  kind: 'ModbusTCPServer',
  equipmentId: '',
  registerId: '',
  request: MODBUS_TCP_WRITE_REGISTER_REQUEST_DEFAULT,
};
