import { haasRunProgramStepValidators } from '@sb/integrations/implementations/HaasMill/frontend/haasRunProgramStepValidator';
import type { Routine, Step } from '@sb/remote-control/types';
import { makeJSExpressionValidator } from '@sb/remote-control/util/expressions/validateJSExpression';

import { decoratorStepValidator } from './decoratorStepValidator';
import { makeStringValidator, makeJointValidator } from './simpleValidators';
import { stepConfiguredValidator } from './stepConfiguredValidator';
import { actuateGripperValidators } from './steps/actuateGripperValidators';
import { addOffsetValidator } from './steps/addOffsetValidator';
import { locateValidator } from './steps/locateValidator';
import { loopControlValidator } from './steps/loopControlValidator';
import { moveArmToValidators } from './steps/moveArmToValidators';
import { networkRequestValidator } from './steps/networkRequestValidator';
import { runInBackgroundValidator } from './steps/runInBackgroundValidator';
import { runSkillValidator } from './steps/runSkillValidator';
import { setEnvironmentVariableValidator } from './steps/setEnvironmentVariableValidator';
import type { StepValidator } from './types';
import { makeConditionalValidator } from './validateConditional';

const validators: StepValidator[] = [
  stepConfiguredValidator,
  decoratorStepValidator,
  addOffsetValidator,
  loopControlValidator,
  runInBackgroundValidator,
  runSkillValidator,
  networkRequestValidator,
  setEnvironmentVariableValidator,
  locateValidator,
  ...haasRunProgramStepValidators,
  ...actuateGripperValidators,
  ...moveArmToValidators,
  makeJointValidator('PressButton', 'prePressTarget', 'Pre-Press Target', {
    allowUndefined: false,
  }),
  makeJointValidator('PressButton', 'pressTarget', 'Press Target', {
    allowUndefined: false,
  }),
  makeStringValidator('HaasRunProgram', 'programName', 'Program Name', {
    match: /^\d+\.nc$/,
    message: 'Program Name must contain numbers and end in .nc',
  }),
  makeStringValidator('WaitForConfirmation', 'message', 'Message'),
  makeStringValidator('Locate', 'positionListName', 'Position List', {
    message: 'Requires valid Position List',
  }),
  makeStringValidator('Classify', 'fallbackValue', 'Fallback Classification', {
    message: 'Requires valid Fallback Classification',
  }),
  makeConditionalValidator('Loop', 'condition', 'Condition'),
  makeConditionalValidator('If', 'condition', 'Condition'),
  makeConditionalValidator('Wait', 'condition', 'Condition'),
  makeJSExpressionValidator('MoveArmTo', 'expression', 'Position expression'),
  makeJSExpressionValidator('AddOffset', 'translationX', 'X Translation'),
  makeJSExpressionValidator('AddOffset', 'translationY', 'Y Translation'),
  makeJSExpressionValidator('AddOffset', 'translationZ', 'Z Translation'),
  makeJSExpressionValidator('SetEnvironmentVariable', 'newValue', 'New Value'),
];

export function getStepActionRequired(
  step: Step.ConvertedSummary,
  routine: Routine.ConvertedResponse,
): Routine.ActionRequiredStep | undefined {
  for (const stepValidator of validators) {
    const actionRequired = stepValidator(
      step,
      routine.stepConfigurations[step.id],
      routine,
    );

    if (actionRequired) {
      return actionRequired;
    }
  }

  return undefined;
}
