import type { Expression } from '@sb/routine-runner';

import type { FieldValidatorCreator } from '../step-validation/types';

export const makeJSExpressionValidator: FieldValidatorCreator<boolean> =
  (stepKind, fieldId, name, allowUndefined = true) =>
  (_step, stepConfiguration) => {
    if (stepConfiguration?.stepKind !== stepKind) return undefined;
    const { args } = stepConfiguration;
    if (!args) return undefined;

    const value = (args as any)[fieldId] as Expression | undefined;

    if (!value) {
      return allowUndefined
        ? undefined
        : {
            kind: 'invalidConfiguration',
            message: `${name} not specified`,
            fieldId,
          };
    }

    if (value.kind !== 'JavaScript') return undefined;

    if (value.errorMessage) {
      return {
        kind: 'invalidConfiguration',
        message: `Error in ${name}: ${value.errorMessage}`,
        fieldId,
      };
    }

    return undefined;
  };
