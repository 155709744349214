import * as zod from 'zod';

import {
  MODBUS_TCP_STEP_ARGS_DEFAULT,
  ModbusTCPServerStepArguments,
} from '@sb/integrations/implementations/ModbusTCPServer/step/constants';
import type { Equipment } from '@sb/remote-control/types';
import type { NetworkRequestArguments } from '@sb/routine-runner/Step/NetworkRequest/Arguments';

export namespace NetworkRequestStep {
  /** Arguments to be passed to the routine runner */
  export const Arguments = zod.object({
    argumentKind: zod.literal('NetworkRequest'),
    command: ModbusTCPServerStepArguments.default(MODBUS_TCP_STEP_ARGS_DEFAULT),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const convertStepToRoutineRunnerArgs = (
    configuration: Arguments | void,
    stepID: string,
    equipment: Equipment.ConvertedResponse[],
  ): NetworkRequestArguments => {
    if (!configuration) {
      throw new Error(
        `'Network Request step is not configured. stepID: ${stepID}'`,
      );
    }

    if (configuration.command.kind === 'ModbusTCPServer') {
      const equipmentConfig = equipment.find(
        (e) => e.id === configuration.command.equipmentId,
      );

      if (!equipmentConfig) {
        throw new Error(
          `Step ${stepID} incorrectly configured: Unable to find device id ${configuration.command.equipmentId} in equipment manager.`,
        );
      }

      if (
        configuration.command.kind === 'ModbusTCPServer' &&
        equipmentConfig?.config.kind === 'ModbusTCPServer'
      ) {
        const equipmentRegister = equipmentConfig.config.registers?.find(
          (r) => r.id === configuration.command.registerId,
        );

        if (equipmentRegister) {
          return {
            command: {
              kind: configuration.command.kind,
              equipmentId: configuration.command.equipmentId,
              request: {
                ...configuration.command.request,
                register: equipmentRegister,
              },
            },
          };
        }

        throw new Error(
          `$Step ${stepID} incorrectly configured: Unable to find register id ${configuration.command.registerId} in equipment device configuration.`,
        );
      }
    }

    throw new Error(
      `${configuration.command.kind} is an unsupported Network Request Step kind`,
    );
  };
}
