import type { ArmPosition } from '@sb/routine-runner';

import type { FieldValidatorCreator } from './types';

export const makeStringValidator: FieldValidatorCreator<{
  allowUndefined?: boolean;
  allowEmpty?: boolean;
  match?: RegExp;
  message?: string;
}> =
  (stepKind, fieldId, name, options = {}) =>
  (_step, stepConfiguration) => {
    if (stepConfiguration?.stepKind !== stepKind) return undefined;
    const { args } = stepConfiguration;
    if (!args) return undefined;

    const value = (args as any)[fieldId] as string | undefined;

    if (value === undefined && !options.allowUndefined) {
      return {
        kind: 'invalidConfiguration',
        message: options.message ?? `${name} not specified`,
        fieldId,
      };
    }

    if (value?.length === 0 && !options.allowEmpty) {
      return {
        kind: 'invalidConfiguration',
        message: options.message ?? `${name} must not be empty`,
        fieldId,
      };
    }

    if (options.match && !options.match.test(value ?? '')) {
      return {
        kind: 'invalidConfiguration',
        message: options.message ?? `${name} must match ${options.match}`,
        fieldId,
      };
    }

    return undefined;
  };

export const makeJointValidator: FieldValidatorCreator<{
  message?: string;
  allowUndefined?: boolean;
}> =
  (stepKind, fieldId, name, options = {}) =>
  (_step, stepConfiguration) => {
    if (stepConfiguration?.stepKind !== stepKind) return undefined;
    const { args } = stepConfiguration;
    if (!args) return undefined;

    const value = (args as any)[fieldId] as ArmPosition | undefined | null;

    if (!options.allowUndefined && (value === undefined || value === null)) {
      return {
        kind: 'invalidConfiguration',
        message: options.message ?? `${name} not specified`,
        fieldId,
      };
    }

    if (!value?.jointAngles && !value?.pose) {
      return {
        kind: 'invalidConfiguration',
        message:
          options.message ??
          `Joint positions or pose must be specified for ${name}`,
        fieldId,
      };
    }

    return undefined;
  };
