import cx from 'classnames';

import {
  useComponentColorClassName,
  type ComponentColor,
} from '../../../utility';

type NeedleColor = ComponentColor | 'Gray';

const COLOR_CLASSES: Record<NeedleColor, string> = {
  Orange: 'tw-bg-orange-40 dark:tw-bg-orange-50',
  Red: 'tw-bg-red-40 dark:tw-bg-red-50',
  Green: 'tw-bg-green-30 dark:tw-bg-green-40',
  Blue: 'tw-bg-blue-40 dark:tw-bg-blue-50',
  Gray: 'tw-bg-metal-40 dark:tw-bg-metal-80',
  Primary: '',
};

interface NeedleProps {
  color?: NeedleColor | 'Default';
}

export function Needle({ color }: NeedleProps) {
  const colorClassName = useComponentColorClassName(color, COLOR_CLASSES);

  return (
    <div
      className={cx(
        'tw-flex-none',
        'tw-w-2',
        'tw-border-label-primary',
        'tw-border-y-[2px]',
        colorClassName,
        'tw-self-stretch',
      )}
    />
  );
}
