import { ActuateGripperStep } from '../implementations/gripper-general/steps/ActuateGripper/Step';
import { HaasControlRegionStep } from '../implementations/HaasMill/steps/HaasControlRegion/Step';
import { HaasRunProgramStep } from '../implementations/HaasMill/steps/HaasRunProgram/Step';
import { ActuateScrewdriverStep } from '../implementations/OnRobotScrewdriver/steps/ActuateScrewdriver/Step';
import { ActuateVacuumStep } from '../implementations/OnRobotVGP20/steps/ActuateVacuum/Step';

/**
 * Step definitions (including associated types) for controlling
 * devices from routines.
 */
export const StepDefinitions = {
  ActuateGripper: ActuateGripperStep,
  ActuateScrewdriver: ActuateScrewdriverStep,
  ActuateVacuum: ActuateVacuumStep,
  HaasControlRegion: HaasControlRegionStep,
  HaasRunProgram: HaasRunProgramStep,
} as const;
