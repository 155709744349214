import { clamp } from 'lodash';

import type { RobotData } from '@sb/feathers-types';
import type { Equipment, Robot } from '@sb/remote-control/types';
import type {
  ExternalControlSettings,
  SafeguardRule,
} from '@sb/routine-runner';

import { getIOPorts } from './getIOPorts';

export function convertRobotResponse(
  robotData: RobotData,
  safeguardRules: SafeguardRule[],
  externalControlSettings: ExternalControlSettings | undefined,
  equipment: Equipment.ConvertedResponse[],
): Robot.ConvertedResponse {
  const ioInputs = getIOPorts<Robot.InputPort>(
    'Input',
    robotData.ioInputs,
    safeguardRules,
    externalControlSettings,
    equipment,
  );

  const ioOutputs = getIOPorts<Robot.OutputPort>(
    'Output',
    robotData.ioOutputs,
    safeguardRules,
    externalControlSettings,
    equipment,
  );

  return {
    id: robotData.robotID,
    ioInputs,
    ioOutputs,
    latestRoutineID: robotData.latestRoutineID,
    latestRoutineLoadedAt: robotData.latestRoutineLoadedAt
      ? new Date(robotData.latestRoutineLoadedAt)
      : undefined,
    name: robotData.name,
    routineRunnerState: {
      kind: robotData.routineRunnerStateKind ?? 'Constructing',
      isPaused: robotData.routineIsPaused,
    },
    speedRestrictionPercentage: clamp(
      robotData.speedRestrictionPercentage,
      0,
      1,
    ),
    testRunCompletedAt: robotData.testRunCompletedAt
      ? new Date(robotData.testRunCompletedAt)
      : undefined,
    lastKnownLocalIP: robotData.lastKnownLocalIP,
  };
}
