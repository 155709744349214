import type {
  RoutineRunnerState,
  RoutineRunning,
  SafeguardKind,
} from '@sb/routine-runner';

export namespace Robot {
  export type FrameOfReference = 'tooltip' | 'robotArm';

  export type StartConditions = Pick<
    RoutineRunning,
    'currentStepID' | 'variables'
  >;

  export type IOPortCommon = {
    highSignalName: string;
    lowSignalName: string;
    name: string;
    port: number;
    label?: string;
    assignedTo?: string;
    safeguardKind?: SafeguardKind;
  };

  export type InputPort = IOPortCommon & { kind: 'Input' };
  export type OutputPort = IOPortCommon & { kind: 'Output' };
  export type SafetyPort = IOPortCommon & { kind: 'Safety' };

  export type IOPort = InputPort | OutputPort | SafetyPort;

  export type IOPortKind = IOPort['kind'];

  export const IO_PORT_ASSIGN_TO_SAFETY_IO = 'Safety I/O';
  export const IO_PORT_ASSIGN_TO_EXTERNAL_CONTROL = 'External control';

  export interface EditableFields {
    name: string;
  }

  export interface Update extends Partial<EditableFields> {
    ioInputs?: IOPort[];
    ioOutputs?: IOPort[];
    isTestRunCompleted?: boolean;
    latestRoutineID?: string | null;
    speedRestrictionPercentage?: number;
  }

  /** Response converted to the format used in the frontend. */
  export interface ConvertedResponse extends EditableFields {
    id: string;
    ioInputs: InputPort[];
    ioOutputs: OutputPort[];
    latestRoutineID: string | null;
    latestRoutineLoadedAt?: Date;
    /**
     * This field was added to the robot document for querying robots.
     * For now we only care about certain fields, so we're not updating the
     * entire routine runner because this would be very expensive.
     */
    routineRunnerState: {
      kind: RoutineRunnerState['kind'] | 'Constructing';
      isPaused: boolean;
    };
    speedRestrictionPercentage: number;
    testRunCompletedAt?: Date;
    lastKnownLocalIP: string | null;
  }
}
