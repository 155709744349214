import type { MoveArmToTargetStep } from '@sb/remote-control/types';
import type { MoveArmToStepArguments, SpeedProfile } from '@sb/routine-runner';

import { calculateSpeedProfileFromStepMotionSpeed } from '../calculateSpeedProfileFromStepMotionSpeed';
import { validateMotionSpeed } from '../validators';

export const convertMoveArmToConfigurationToRoutineRunner = (
  stepID: string,
  baseSpeedProfile: SpeedProfile,
  configuration?: MoveArmToTargetStep.Arguments,
): MoveArmToStepArguments => {
  if (!configuration) {
    throw new Error(`MoveArmTo step is not configured. stepID: ${stepID}`);
  }

  const {
    expression,
    target,
    targetKind,
    motionKind,
    shouldMatchJointAngles,
    reduceSmoothing,
    tcpOffsetOption,
    motionSpeed,
    positionListID,
    positionListIndex,
    isWaypoint,
    isCacheable,
    blend,
  } = configuration;

  if (!motionKind) {
    throw new Error(
      `MoveArmTo step motionKind is not configured. stepID: ${stepID}`,
    );
  }

  validateMotionSpeed('Move Arm', motionSpeed);

  const speedProfile: SpeedProfile = calculateSpeedProfileFromStepMotionSpeed({
    motionSpeed,
    baseSpeedProfile,
  });

  const getTarget = (): MoveArmToStepArguments['target'] => {
    switch (targetKind) {
      case 'positionList':
        if (positionListID) {
          return { positionListID, positionListIndex };
        }

        break;

      case 'expression':
        if (expression) {
          return { expression };
        }

        break;

      default:
        if (target) {
          return target;
        }
    }

    throw new Error(
      `MoveArmTo has an invalid configuration. stepID: ${stepID}`,
    );
  };

  return {
    motionKind,
    shouldMatchJointAngles,
    reduceSmoothing,
    tcpOffsetOption,
    speedProfile,
    target: getTarget(),
    isWaypoint: isWaypoint ?? false,
    isCacheable: isCacheable ?? false,
    blend,
  };
};
