import type { StepValidator } from '../types';

/** Is LoopControl step that isn't inside a loop. */
export const loopControlValidator: StepValidator = (step) => {
  // Only check `LoopControl` steps.
  if (step.stepKind !== 'LoopControl') return undefined;

  // Check if this step is a child of a 'Loop' step.
  const isParentALoopStep = step.parentSteps.some(
    (parentStep) => parentStep?.stepKind === 'Loop',
  );

  // If we can't find any 'Loop' steps in parent nodes, then
  // this step is invalid.
  if (isParentALoopStep) return undefined;

  return {
    kind: 'invalidConfiguration',
    message: 'Move Restart Loop inside a Loop step',
  };
};
