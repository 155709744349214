import cx from 'classnames';

import {
  useComponentColorClassName,
  type ComponentColor,
  type ComponentColorOrDefault,
} from '../../../utility';
import { calculateClampedPercent } from '../util';

import { Needle } from './Needle';
import { ValueLabel } from './ValueLabel';

const COLOR_CLASSES: Record<ComponentColor, string> = {
  Orange: 'tw-from-orange-20 dark:tw-from-orange-80',
  Red: 'tw-from-red-20 dark:tw-from-red-80',
  Green: 'tw-from-green-20 dark:tw-from-green-70',
  Blue: 'tw-from-blue-20 dark:tw-from-blue-70',
  Primary: '',
};

export interface NeedleGaugeProps {
  min: number;
  max: number;
  value: number;
  targetValue?: number;
  valueToString: (value: number) => string;
  color?: ComponentColorOrDefault;
}

export function NeedleGauge({
  min,
  max,
  value,
  targetValue,
  valueToString,
  color,
}: NeedleGaugeProps) {
  const colorClassName = useComponentColorClassName(color, COLOR_CLASSES);

  const valueAsPercent = calculateClampedPercent(value, min, max);

  const targetValueAsPercent =
    targetValue === undefined
      ? NaN
      : calculateClampedPercent(targetValue, min, max);

  const targetLowerPercent =
    targetValueAsPercent < valueAsPercent
      ? valueAsPercent - targetValueAsPercent
      : 0;

  const targetHigherPercent =
    targetValueAsPercent > valueAsPercent
      ? targetValueAsPercent - valueAsPercent
      : 0;

  return (
    <>
      <div
        className={cx('tw-flex-none')}
        style={{ flexGrow: valueAsPercent - targetLowerPercent }}
      />

      {targetLowerPercent > 0 && (
        <div
          className={cx(
            'tw-flex-none',
            'tw-bg-gradient-to-r',
            colorClassName,
            'tw-flex',
            'tw-w-0',
          )}
          style={{ flexGrow: targetLowerPercent }}
        >
          <Needle color={color} />
        </div>
      )}

      <Needle color="Gray" />

      {targetHigherPercent > 0 && (
        <div
          className={cx(
            'tw-flex-none',
            'tw-bg-gradient-to-l',
            colorClassName,
            'tw-flex',
            'tw-justify-end',
            'tw-w-0',
          )}
          style={{ flexGrow: targetHigherPercent }}
        >
          <Needle color={color} />
        </div>
      )}

      <div
        className={cx('tw-flex-none')}
        style={{ flexGrow: 100 - valueAsPercent - targetHigherPercent }}
      />

      {targetValue === undefined ? (
        <ValueLabel value={valueToString(value)} percent={50} color="Gray" />
      ) : (
        <>
          <ValueLabel
            value={valueToString(value)}
            percent={valueAsPercent}
            placement="TopMiddle"
            color="Gray"
          />
          <ValueLabel
            value={valueToString(targetValue!)}
            percent={targetValueAsPercent}
            color={color}
            placement={
              targetValueAsPercent > valueAsPercent
                ? 'BottomLeft'
                : 'BottomRight'
            }
          />
        </>
      )}
    </>
  );
}
