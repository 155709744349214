import { clamp } from 'lodash';

import type { ArmJointTorques } from '@sb/motion-planning';
import { ABSOLUTE_MAX_JOINT_TORQUES } from '@sb/motion-planning';
import type { PushModeStep } from '@sb/remote-control/types';
import type { PushModeStepArguments } from '@sb/routine-runner';

export function convertPushModeConfigurationToRoutineRunner(
  stepID: string,
  configuration?: PushModeStep.Arguments,
): PushModeStepArguments {
  if (!configuration) {
    throw new Error(`MoveArmTo step is not configured. stepID: ${stepID}`);
  }

  const { pushingForcePercent } = configuration;

  const collisionThresholds: ArmJointTorques | undefined = (() => {
    if (!pushingForcePercent) {
      return undefined;
    }

    // 10% = 0.1; 100% = 0.6 (as per SW-1886)
    const MIN_FRAC = 0.1;
    const MAX_FRAC = 0.6;
    const fraction = (pushingForcePercent - 10) / 180 + MIN_FRAC;
    const clampedFraction = clamp(fraction, MIN_FRAC, MAX_FRAC);

    return ABSOLUTE_MAX_JOINT_TORQUES.map((torque) => {
      return torque * clampedFraction;
    }) as ArmJointTorques;
  })();

  return { collisionThresholds };
}
