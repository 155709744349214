import type { ConditionalArgument } from '@sb/remote-control/types';

import type { FieldValidatorCreator } from './types';

const isValidConditional = (
  configuration?: ConditionalArgument.ConditionalBuilderState[][],
): boolean => {
  if (!configuration) return false;

  // Checks whether the configuration includes a conditional group
  // that includes null as any of its values
  return !configuration.some((conditionalGroup) =>
    conditionalGroup.some((condition) =>
      Object.values(condition).includes(null),
    ),
  );
};

export const makeConditionalValidator: FieldValidatorCreator<boolean> =
  (stepKind, fieldId, name, allowUndefined = true) =>
  (_step, stepConfiguration) => {
    if (stepConfiguration?.stepKind !== stepKind) return undefined;
    const { args } = stepConfiguration;
    if (!args) return undefined;

    const rawValue = (args as any)[fieldId] as string | undefined;

    if (!rawValue) {
      return allowUndefined
        ? undefined
        : {
            kind: 'invalidConfiguration',
            message: `${name} not specified`,
            fieldId,
          };
    }

    try {
      const value = JSON.parse(rawValue) as
        | ConditionalArgument.ConditionalBuilderState[][]
        | undefined;

      if (!value) {
        return allowUndefined
          ? undefined
          : {
              kind: 'invalidConfiguration',
              message: `${name} not specified`,
              fieldId,
            };
      }

      if (!isValidConditional(value)) {
        return {
          kind: 'invalidConfiguration',
          message: `Invalid ${name} configuration`,
          fieldId,
        };
      }
    } catch (error) {
      return {
        kind: 'invalidConfiguration',
        message: `Error in ${name}: ${error.message}`,
        fieldId,
      };
    }

    return undefined;
  };
