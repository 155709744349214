import * as zod from 'zod';

import { CartesianPose, ZERO_POSE } from '@sb/geometry';
import { CameraIntegration } from '@sb/integrations/types/cameraTypes';
import {
  DEFAULT_REGION_OF_INTEREST,
  LocateMethod,
  RegionOfInterest,
} from '@sb/routine-runner';

export namespace LocateStep {
  export const Arguments = zod.object({
    argumentKind: zod.literal('Locate'),
    camera: CameraIntegration,
    regionOfInterest: RegionOfInterest.default(DEFAULT_REGION_OF_INTEREST),
    method: LocateMethod,
    positionListName: zod.string().default('Locate step results'),
    resultsLimit: zod.number().default(5),
    transform: CartesianPose.default(ZERO_POSE),
    planeID: zod.string().optional(),
  });

  export type Arguments = zod.infer<typeof Arguments>;
}
