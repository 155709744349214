import { map } from 'rxjs/operators';

import { asPaginated } from '@sb/feathers-types';
import type { Unsubscribe } from '@sb/remote-control/types';
import { ExternalControlSettings } from '@sb/routine-runner';

import { getExternalControlSettingsService } from './getExternalControlSettingsService';

export function onGetExternalControlSettings(
  robotID: string,
  onSnapshot: (externalControlSettings: ExternalControlSettings) => void,
): Unsubscribe {
  const subscription = getExternalControlSettingsService()
    .watch()
    .find({ query: { robotID, $limit: 1 } })
    .pipe(
      map((response) => asPaginated(response).data[0]),
      map((data) => ExternalControlSettings.parse(data ?? {})),
    )
    .subscribe(onSnapshot);

  return () => subscription.unsubscribe();
}
