import * as zod from 'zod';

import type { GripperState } from '@sb/integrations/implementations/gripper-general';

import type { KinematicState } from '../../RoutineRunnerState';
import type { ArmPositionList } from '../ArmPosition';

export const JavaScriptExpression = zod.object({
  kind: zod.literal('JavaScript'),
  expression: zod.string(),
  errorMessage: zod.string().optional().nullable(),
});

export type JavaScriptExpression = zod.infer<typeof JavaScriptExpression>;

export type JSRunner = {
  evaluate(args: {
    expression: string;
    context: {
      kinematicState: {
        ioState: KinematicState['ioState'];
        gripperState: GripperState;
      };
      variables: Record<string, any>;
      routine: {
        positionLists: Record<string, ArmPositionList>;
      };
    };
  }): Promise<unknown>;
};
