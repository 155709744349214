import { CustomGripperConfiguration } from '../implementations/CustomGripper/types/CustomGripperConfiguration';
import { CustomIOConfiguration } from '../implementations/CustomIO/types/CustomIOConfiguration';
import { DHAG105145Configuration } from '../implementations/dh/instances/DHAG_105_145/Configuration';
import { EwellixLiftTLTConfiguration } from '../implementations/EwellixLiftTLT/types';
import { InvalidConfiguration } from '../implementations/InvalidConfiguration/types';
import { ModbusTCPServerConfiguration } from '../implementations/ModbusTCPServer/types';
import { NoGripperConfiguration } from '../implementations/NoGripper/types';
import { OnRobot2FG7Configuration } from '../implementations/OnRobot2FG7/types';
import { OnRobot3FG15Configuration } from '../implementations/OnRobot3FG15/types';
import { OnRobotDualQuickChangerConfiguration } from '../implementations/OnRobotDualQuickChanger/types';
import { OnRobotScrewdriverConfiguration } from '../implementations/OnRobotScrewdriver/types';
import { OnRobotVGP20Configuration } from '../implementations/OnRobotVGP20/types';
import { WristCameraConfiguration } from '../implementations/WristCamera/types';

/**
 * DeviceConfiguration is the configuration for a device.
 * Configuration can include information such as IP address, port, finger width, etc.
 *
 * Configuration is passed into the core device implementation when the device is created.
 */
export const DeviceConfigurationRegistry = [
  OnRobotDualQuickChangerConfiguration,
  OnRobotScrewdriverConfiguration,
  OnRobotVGP20Configuration,
  OnRobot2FG7Configuration,
  OnRobot3FG15Configuration,
  CustomGripperConfiguration,
  EwellixLiftTLTConfiguration,
  NoGripperConfiguration,
  CustomIOConfiguration,
  InvalidConfiguration,
  ModbusTCPServerConfiguration,
  WristCameraConfiguration,
  DHAG105145Configuration,
] as const;
