import * as zod from 'zod';

export namespace PushModeStep {
  export const Arguments = zod.object({
    argumentKind: zod.literal('PushMode'),
    pushingForcePercent: zod.number().optional(),
  });

  export type Arguments = zod.infer<typeof Arguments>;
}
