import type { StepValidator } from '../types';

export const setEnvironmentVariableValidator: StepValidator = (
  _step,
  stepConfiguration,
) => {
  if (stepConfiguration?.stepKind !== 'SetEnvironmentVariable')
    return undefined;
  const { args } = stepConfiguration;
  if (!args) return undefined;

  if (!args.variableID) {
    return {
      kind: 'invalidConfiguration',
      message: 'Variable ID required',
      fieldId: 'variableID',
    };
  }

  if (!args.newValue) {
    return {
      kind: 'invalidConfiguration',
      message: 'Value is required',
      fieldId: 'newValue',
    };
  }

  return undefined;
};
