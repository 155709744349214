/* tslint:disable */
/* eslint-disable */
/**
 * Standard Bots RO1 Robotics API
 * Standard Bots RO1 Robotics API.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: support@standardbots.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * Rotational positions of arm joints
 * @export
 * @interface ArmJointRotations
 */
export interface ArmJointRotations {
    /**
     * 
     * @type {any}
     * @memberof ArmJointRotations
     */
    'joints': any;
}
/**
 * Move robot event when motion is canceled
 * @export
 * @interface ArmPositionUpdateCanceledEvent
 */
export interface ArmPositionUpdateCanceledEvent {
    /**
     * 
     * @type {any}
     * @memberof ArmPositionUpdateCanceledEvent
     */
    'kind'?: any;
    /**
     * 
     * @type {any}
     * @memberof ArmPositionUpdateCanceledEvent
     */
    'reason'?: any;
}
/**
 * Event emitted by the move robot API
 * @export
 * @interface ArmPositionUpdateEvent
 */
export interface ArmPositionUpdateEvent {
    /**
     * 
     * @type {any}
     * @memberof ArmPositionUpdateEvent
     */
    'kind'?: ArmPositionUpdateEventKindEnum;
    /**
     * 
     * @type {ArmPositionUpdateFailureEvent}
     * @memberof ArmPositionUpdateEvent
     */
    'failure'?: ArmPositionUpdateFailureEvent;
    /**
     * 
     * @type {ArmPositionUpdateCanceledEvent}
     * @memberof ArmPositionUpdateEvent
     */
    'canceled'?: ArmPositionUpdateCanceledEvent;
    /**
     * 
     * @type {CombinedArmPosition}
     * @memberof ArmPositionUpdateEvent
     */
    'position'?: CombinedArmPosition;
}

export const ArmPositionUpdateEventKindEnum = {
    Success: 'success',
    Failure: 'failure',
    Canceled: 'canceled',
    Position: 'position',
    Planning: 'planning'
} as const;

export type ArmPositionUpdateEventKindEnum = typeof ArmPositionUpdateEventKindEnum[keyof typeof ArmPositionUpdateEventKindEnum];

/**
 * Move robot event when movement failed
 * @export
 * @interface ArmPositionUpdateFailureEvent
 */
export interface ArmPositionUpdateFailureEvent {
    /**
     * 
     * @type {any}
     * @memberof ArmPositionUpdateFailureEvent
     */
    'kind': ArmPositionUpdateFailureEventKindEnum;
    /**
     * 
     * @type {any}
     * @memberof ArmPositionUpdateFailureEvent
     */
    'reason': any;
}

export const ArmPositionUpdateFailureEventKindEnum = {
    UnknownReason: 'motion_failed_unknown_reason',
    Collision: 'motion_failed_collision'
} as const;

export type ArmPositionUpdateFailureEventKindEnum = typeof ArmPositionUpdateFailureEventKindEnum[keyof typeof ArmPositionUpdateFailureEventKindEnum];

/**
 * Move the robot
 * @export
 * @interface ArmPositionUpdateRequest
 */
export interface ArmPositionUpdateRequest {
    /**
     * 
     * @type {any}
     * @memberof ArmPositionUpdateRequest
     */
    'kind': ArmPositionUpdateRequestKindEnum;
    /**
     * 
     * @type {any}
     * @memberof ArmPositionUpdateRequest
     */
    'tooltip_positions'?: any;
    /**
     * 
     * @type {PositionAndOrientation}
     * @memberof ArmPositionUpdateRequest
     */
    'tooltip_position'?: PositionAndOrientation;
    /**
     * 
     * @type {any}
     * @memberof ArmPositionUpdateRequest
     */
    'joint_rotations'?: any;
    /**
     * 
     * @type {ArmJointRotations}
     * @memberof ArmPositionUpdateRequest
     */
    'joint_rotation'?: ArmJointRotations;
}

export const ArmPositionUpdateRequestKindEnum = {
    TooltipPositions: 'tooltip_positions',
    TooltipPosition: 'tooltip_position',
    JointRotations: 'joint_rotations',
    JointRotation: 'joint_rotation'
} as const;

export type ArmPositionUpdateRequestKindEnum = typeof ArmPositionUpdateRequestKindEnum[keyof typeof ArmPositionUpdateRequestKindEnum];

/**
 * Specify how the response should be sent 
 * @export
 * @interface ArmPositionUpdateRequestResponseEventStreamDetails
 */
export interface ArmPositionUpdateRequestResponseEventStreamDetails {
    /**
     * 
     * @type {any}
     * @memberof ArmPositionUpdateRequestResponseEventStreamDetails
     */
    'subscriptions': any;
}
/**
 * State of the robot joint brakes. Each joint contains a brake which can be engaged when the robot is at a standstill to prevent the robot from moving. 
 * @export
 * @interface BrakesState
 */
export interface BrakesState {
    /**
     * 
     * @type {any}
     * @memberof BrakesState
     */
    'state': BrakesStateStateEnum;
}

export const BrakesStateStateEnum = {
    Engaged: 'engaged',
    Disengaged: 'disengaged'
} as const;

export type BrakesStateStateEnum = typeof BrakesStateStateEnum[keyof typeof BrakesStateStateEnum];

/**
 * Combined tooltip position and joint rotations defining the arm\'s position
 * @export
 * @interface CombinedArmPosition
 */
export interface CombinedArmPosition {
    /**
     * 
     * @type {any}
     * @memberof CombinedArmPosition
     */
    'joint_rotations': any;
    /**
     * 
     * @type {PositionAndOrientation}
     * @memberof CombinedArmPosition
     */
    'tooltip_position': PositionAndOrientation;
}
/**
 * Engage Emergency Stop. This will immediately stop the robot and prevent it from moving until the robot is unbraked. 
 * @export
 * @interface EngageEmergencyStopRequest
 */
export interface EngageEmergencyStopRequest {
    /**
     * 
     * @type {any}
     * @memberof EngageEmergencyStopRequest
     */
    'reason'?: any;
}
/**
 * Environment variables for a routine
 * @export
 * @interface EnvironmentVariable
 */
export interface EnvironmentVariable {
    /**
     * 
     * @type {any}
     * @memberof EnvironmentVariable
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof EnvironmentVariable
     */
    'name'?: any;
    /**
     * 
     * @type {any}
     * @memberof EnvironmentVariable
     */
    'value'?: any;
}
/**
 * Error Response
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {any}
     * @memberof ErrorResponse
     */
    'error': ErrorResponseErrorEnum;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'message': string;
}

export const ErrorResponseErrorEnum = {
    AuthorizationRequired: 'authorization_required',
    RoutineMustBeRunning: 'routine_must_be_running',
    ApiControlRequired: 'api_control_required',
    RobotBrakesDisengageFailed: 'robot_brakes_disengage_failed',
    RobotBrakesEngageFailed: 'robot_brakes_engage_failed',
    RequestFailedValidation: 'request_failed_validation',
    BrakesMustBeEngaged: 'brakes_must_be_engaged',
    BrakesMustBeDisengaged: 'brakes_must_be_disengaged',
    EquipmentNoMatching: 'equipment_no_matching',
    ServiceInitializing: 'service_initializing'
} as const;

export type ErrorResponseErrorEnum = typeof ErrorResponseErrorEnum[keyof typeof ErrorResponseErrorEnum];

/**
 * Reusable Abstraction for force units (eg force, torque) 
 * @export
 * @interface ForceUnit
 */
export interface ForceUnit {
    /**
     * 
     * @type {any}
     * @memberof ForceUnit
     */
    'unit_kind': ForceUnitUnitKindEnum;
    /**
     * 
     * @type {any}
     * @memberof ForceUnit
     */
    'value'?: any;
}

export const ForceUnitUnitKindEnum = {
    Newtons: 'newtons',
    Pounds: 'pounds'
} as const;

export type ForceUnitUnitKindEnum = typeof ForceUnitUnitKindEnum[keyof typeof ForceUnitUnitKindEnum];

/**
 * Control the gripper (end effector) of the robot 
 * @export
 * @interface GripperCommandRequest
 */
export interface GripperCommandRequest {
    /**
     * 
     * @type {any}
     * @memberof GripperCommandRequest
     */
    'kind': GripperCommandRequestKindEnum;
    /**
     * 
     * @type {OnRobot2FG7GripperCommandRequest}
     * @memberof GripperCommandRequest
     */
    'onrobot_2fg7'?: OnRobot2FG7GripperCommandRequest;
    /**
     * 
     * @type {OnRobot3FG15GripperCommandRequest}
     * @memberof GripperCommandRequest
     */
    'onrobot_3fg15'?: OnRobot3FG15GripperCommandRequest;
}

export const GripperCommandRequestKindEnum = {
    Onrobot2fg7: 'onrobot_2fg7',
    Onrobot3fg15: 'onrobot_3fg15',
    NoneConnected: 'none_connected'
} as const;

export type GripperCommandRequestKindEnum = typeof GripperCommandRequestKindEnum[keyof typeof GripperCommandRequestKindEnum];

/**
 * Configuration of gripper, also known as End Effector
 * @export
 * @interface GripperConfiguration
 */
export interface GripperConfiguration {
    /**
     * 
     * @type {any}
     * @memberof GripperConfiguration
     */
    'kind': GripperConfigurationKindEnum;
}

export const GripperConfigurationKindEnum = {
    Onrobot2fg7: 'onrobot_2fg7',
    Onrobot3fg15: 'onrobot_3fg15',
    NoneConnected: 'none_connected'
} as const;

export type GripperConfigurationKindEnum = typeof GripperConfigurationKindEnum[keyof typeof GripperConfigurationKindEnum];

/**
 * Reusable Abstraction for linear units (eg distance, position, offset) 
 * @export
 * @interface LinearUnit
 */
export interface LinearUnit {
    /**
     * 
     * @type {any}
     * @memberof LinearUnit
     */
    'unit_kind': LinearUnitUnitKindEnum;
    /**
     * 
     * @type {any}
     * @memberof LinearUnit
     */
    'value'?: any;
}

export const LinearUnitUnitKindEnum = {
    Millimeters: 'millimeters',
    Centimeters: 'centimeters',
    Meters: 'meters',
    Inches: 'inches',
    Feet: 'feet'
} as const;

export type LinearUnitUnitKindEnum = typeof LinearUnitUnitKindEnum[keyof typeof LinearUnitUnitKindEnum];

/**
 * Control the OnRobot 2FG7 gripper (end effector) of the robot 
 * @export
 * @interface OnRobot2FG7GripperCommandRequest
 */
export interface OnRobot2FG7GripperCommandRequest {
    /**
     * 
     * @type {any}
     * @memberof OnRobot2FG7GripperCommandRequest
     */
    'grip_direction': OnRobot2FG7GripperCommandRequestGripDirectionEnum;
    /**
     * 
     * @type {ForceUnit}
     * @memberof OnRobot2FG7GripperCommandRequest
     */
    'target_force'?: ForceUnit;
    /**
     * 
     * @type {LinearUnit}
     * @memberof OnRobot2FG7GripperCommandRequest
     */
    'target_grip_width'?: LinearUnit;
    /**
     * 
     * @type {any}
     * @memberof OnRobot2FG7GripperCommandRequest
     */
    'control_kind': OnRobot2FG7GripperCommandRequestControlKindEnum;
}

export const OnRobot2FG7GripperCommandRequestGripDirectionEnum = {
    Inward: 'inward',
    Outward: 'outward'
} as const;

export type OnRobot2FG7GripperCommandRequestGripDirectionEnum = typeof OnRobot2FG7GripperCommandRequestGripDirectionEnum[keyof typeof OnRobot2FG7GripperCommandRequestGripDirectionEnum];
export const OnRobot2FG7GripperCommandRequestControlKindEnum = {
    Move: 'move',
    ForceGrip: 'force_grip'
} as const;

export type OnRobot2FG7GripperCommandRequestControlKindEnum = typeof OnRobot2FG7GripperCommandRequestControlKindEnum[keyof typeof OnRobot2FG7GripperCommandRequestControlKindEnum];

/**
 * Control the OnRobot 3FG15 gripper (end effector) of the robot 
 * @export
 * @interface OnRobot3FG15GripperCommandRequest
 */
export interface OnRobot3FG15GripperCommandRequest {
    /**
     * 
     * @type {any}
     * @memberof OnRobot3FG15GripperCommandRequest
     */
    'grip_direction': OnRobot3FG15GripperCommandRequestGripDirectionEnum;
    /**
     * 
     * @type {ForceUnit}
     * @memberof OnRobot3FG15GripperCommandRequest
     */
    'target_force'?: ForceUnit;
    /**
     * 
     * @type {LinearUnit}
     * @memberof OnRobot3FG15GripperCommandRequest
     */
    'target_grip_diameter'?: LinearUnit;
    /**
     * 
     * @type {any}
     * @memberof OnRobot3FG15GripperCommandRequest
     */
    'control_kind': OnRobot3FG15GripperCommandRequestControlKindEnum;
}

export const OnRobot3FG15GripperCommandRequestGripDirectionEnum = {
    Inward: 'inward',
    Outward: 'outward'
} as const;

export type OnRobot3FG15GripperCommandRequestGripDirectionEnum = typeof OnRobot3FG15GripperCommandRequestGripDirectionEnum[keyof typeof OnRobot3FG15GripperCommandRequestGripDirectionEnum];
export const OnRobot3FG15GripperCommandRequestControlKindEnum = {
    Move: 'move',
    ForceGrip: 'force_grip',
    FlexibleForceGrip: 'flexible_force_grip'
} as const;

export type OnRobot3FG15GripperCommandRequestControlKindEnum = typeof OnRobot3FG15GripperCommandRequestControlKindEnum[keyof typeof OnRobot3FG15GripperCommandRequestControlKindEnum];

/**
 * Orientation of an object in 3D space
 * @export
 * @interface Orientation
 */
export interface Orientation {
    /**
     * 
     * @type {any}
     * @memberof Orientation
     */
    'kind': OrientationKindEnum;
    /**
     * 
     * @type {Quaternion}
     * @memberof Orientation
     */
    'quaternion'?: Quaternion;
}

export const OrientationKindEnum = {
    Quaternion: 'quaternion'
} as const;

export type OrientationKindEnum = typeof OrientationKindEnum[keyof typeof OrientationKindEnum];

/**
 * Common Pagination Metadata
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * 
     * @type {any}
     * @memberof Pagination
     */
    'total'?: any;
    /**
     * 
     * @type {any}
     * @memberof Pagination
     */
    'limit'?: any;
    /**
     * 
     * @type {any}
     * @memberof Pagination
     */
    'offset'?: any;
}
/**
 * Plane in 3D space
 * @export
 * @interface Plane
 */
export interface Plane {
    /**
     * 
     * @type {any}
     * @memberof Plane
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof Plane
     */
    'name'?: any;
}
/**
 * Paginated response containing plane data
 * @export
 * @interface PlanesPaginatedResponse
 */
export interface PlanesPaginatedResponse {
    /**
     * 
     * @type {any}
     * @memberof PlanesPaginatedResponse
     */
    'items'?: any;
    /**
     * 
     * @type {Pagination}
     * @memberof PlanesPaginatedResponse
     */
    'pagination'?: Pagination;
}
/**
 * Request to play a routine
 * @export
 * @interface PlayRoutineRequest
 */
export interface PlayRoutineRequest {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof PlayRoutineRequest
     */
    'variables'?: { [key: string]: any; };
}
/**
 * Position of an object in 3D space. All empty values default to 0
 * @export
 * @interface Position
 */
export interface Position {
    /**
     * 
     * @type {any}
     * @memberof Position
     */
    'unit_kind'?: PositionUnitKindEnum;
    /**
     * 
     * @type {any}
     * @memberof Position
     */
    'x'?: any;
    /**
     * 
     * @type {any}
     * @memberof Position
     */
    'y'?: any;
    /**
     * 
     * @type {any}
     * @memberof Position
     */
    'z'?: any;
}

export const PositionUnitKindEnum = {
    Millimeters: 'millimeters',
    Centimeters: 'centimeters',
    Meters: 'meters',
    Inches: 'inches',
    Feet: 'feet'
} as const;

export type PositionUnitKindEnum = typeof PositionUnitKindEnum[keyof typeof PositionUnitKindEnum];

/**
 * Position of the arm tooltip
 * @export
 * @interface PositionAndOrientation
 */
export interface PositionAndOrientation {
    /**
     * 
     * @type {any}
     * @memberof PositionAndOrientation
     */
    'axis_alignment'?: any;
    /**
     * 
     * @type {any}
     * @memberof PositionAndOrientation
     */
    'reference_frame'?: any;
    /**
     * 
     * @type {Position}
     * @memberof PositionAndOrientation
     */
    'position': Position;
    /**
     * 
     * @type {Orientation}
     * @memberof PositionAndOrientation
     */
    'orientation': Orientation;
}
/**
 * Quaternion orientation
 * @export
 * @interface Quaternion
 */
export interface Quaternion {
    /**
     * 
     * @type {any}
     * @memberof Quaternion
     */
    'x'?: any;
    /**
     * 
     * @type {any}
     * @memberof Quaternion
     */
    'y'?: any;
    /**
     * 
     * @type {any}
     * @memberof Quaternion
     */
    'z'?: any;
    /**
     * 
     * @type {any}
     * @memberof Quaternion
     */
    'w'?: any;
}
/**
 * Definition of Robot Control State, which tells you which service or system is controlling the Robot
 * @export
 * @interface RobotControlMode
 */
export interface RobotControlMode {
    /**
     * 
     * @type {any}
     * @memberof RobotControlMode
     */
    'kind'?: RobotControlModeKindEnum;
}

export const RobotControlModeKindEnum = {
    Api: 'api',
    RoutineEditor: 'routine_editor'
} as const;

export type RobotControlModeKindEnum = typeof RobotControlModeKindEnum[keyof typeof RobotControlModeKindEnum];

/**
 * Robot Routine containing steps to automate robot movement and operations
 * @export
 * @interface Routine
 */
export interface Routine {
    /**
     * 
     * @type {any}
     * @memberof Routine
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof Routine
     */
    'name'?: any;
    /**
     * 
     * @type {any}
     * @memberof Routine
     */
    'environment_variables'?: any;
}
/**
 * Paginated response containing routine data
 * @export
 * @interface RoutinesPaginatedResponse
 */
export interface RoutinesPaginatedResponse {
    /**
     * 
     * @type {any}
     * @memberof RoutinesPaginatedResponse
     */
    'items'?: any;
    /**
     * 
     * @type {Pagination}
     * @memberof RoutinesPaginatedResponse
     */
    'pagination'?: Pagination;
}
/**
 * Runtime Variable state
 * @export
 * @interface RuntimeVariable
 */
export interface RuntimeVariable {
    /**
     * 
     * @type {any}
     * @memberof RuntimeVariable
     */
    'value'?: any;
}
/**
 * Status Health Response
 * @export
 * @interface StatusHealthResponse
 */
export interface StatusHealthResponse {
    /**
     * 
     * @type {any}
     * @memberof StatusHealthResponse
     */
    'health'?: StatusHealthResponseHealthEnum;
    /**
     * 
     * @type {StatusVersionData}
     * @memberof StatusHealthResponse
     */
    'build'?: StatusVersionData;
}

export const StatusHealthResponseHealthEnum = {
    Ok: 'ok',
    Warning: 'warning',
    Error: 'error'
} as const;

export type StatusHealthResponseHealthEnum = typeof StatusHealthResponseHealthEnum[keyof typeof StatusHealthResponseHealthEnum];

/**
 * Version Data
 * @export
 * @interface StatusVersionData
 */
export interface StatusVersionData {
    /**
     * 
     * @type {any}
     * @memberof StatusVersionData
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof StatusVersionData
     */
    'name'?: any;
}

/**
 * EquipmentApi - axios parameter creator
 * @export
 */
export const EquipmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Send commands to control the Gripper (End Effector) of the robot. The gripper can be any of Standard Bots supported grippers. 
         * @summary Control Gripper
         * @param {GripperCommandRequest} [gripperCommandRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlGripper: async (gripperCommandRequest?: GripperCommandRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/equipment/end-effector/control`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json; charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gripperCommandRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the current gripper configuration 
         * @summary Get Gripper Configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGripperConfiguration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/equipment/end-effector/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EquipmentApi - functional programming interface
 * @export
 */
export const EquipmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EquipmentApiAxiosParamCreator(configuration)
    return {
        /**
         * Send commands to control the Gripper (End Effector) of the robot. The gripper can be any of Standard Bots supported grippers. 
         * @summary Control Gripper
         * @param {GripperCommandRequest} [gripperCommandRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlGripper(gripperCommandRequest?: GripperCommandRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlGripper(gripperCommandRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EquipmentApi.controlGripper']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Get the current gripper configuration 
         * @summary Get Gripper Configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGripperConfiguration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GripperConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGripperConfiguration(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EquipmentApi.getGripperConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * EquipmentApi - factory interface
 * @export
 */
export const EquipmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EquipmentApiFp(configuration)
    return {
        /**
         * Send commands to control the Gripper (End Effector) of the robot. The gripper can be any of Standard Bots supported grippers. 
         * @summary Control Gripper
         * @param {GripperCommandRequest} [gripperCommandRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlGripper(gripperCommandRequest?: GripperCommandRequest, options?: any): AxiosPromise<void> {
            return localVarFp.controlGripper(gripperCommandRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the current gripper configuration 
         * @summary Get Gripper Configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGripperConfiguration(options?: any): AxiosPromise<GripperConfiguration> {
            return localVarFp.getGripperConfiguration(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EquipmentApi - object-oriented interface
 * @export
 * @class EquipmentApi
 * @extends {BaseAPI}
 */
export class EquipmentApi extends BaseAPI {
    /**
     * Send commands to control the Gripper (End Effector) of the robot. The gripper can be any of Standard Bots supported grippers. 
     * @summary Control Gripper
     * @param {GripperCommandRequest} [gripperCommandRequest] Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public controlGripper(gripperCommandRequest?: GripperCommandRequest, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).controlGripper(gripperCommandRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the current gripper configuration 
     * @summary Get Gripper Configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public getGripperConfiguration(options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).getGripperConfiguration(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MovementBrakesApi - axios parameter creator
 * @export
 */
export const MovementBrakesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Engage Emergency braking system. > **⚠️ Warning:** This will immediately stop the robot and may cause damage to the robot or surrounding environment. 
         * @summary Engage Emergency Stop
         * @param {EngageEmergencyStopRequest} [engageEmergencyStopRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engageEmergencyStop: async (engageEmergencyStopRequest?: EngageEmergencyStopRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/movement/brakes/emergency-stop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json; charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(engageEmergencyStopRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the current state of the robot brakes 
         * @summary Get Robot Brakes State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrakesState: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/movement/brakes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Control Joint Brakes in the robot 
         * @summary Control Robot Brakes
         * @param {BrakesState} [brakesState] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBrakesState: async (brakesState?: BrakesState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/movement/brakes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json; charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brakesState, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MovementBrakesApi - functional programming interface
 * @export
 */
export const MovementBrakesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MovementBrakesApiAxiosParamCreator(configuration)
    return {
        /**
         * Engage Emergency braking system. > **⚠️ Warning:** This will immediately stop the robot and may cause damage to the robot or surrounding environment. 
         * @summary Engage Emergency Stop
         * @param {EngageEmergencyStopRequest} [engageEmergencyStopRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engageEmergencyStop(engageEmergencyStopRequest?: EngageEmergencyStopRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.engageEmergencyStop(engageEmergencyStopRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MovementBrakesApi.engageEmergencyStop']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Get the current state of the robot brakes 
         * @summary Get Robot Brakes State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrakesState(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrakesState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrakesState(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MovementBrakesApi.getBrakesState']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Control Joint Brakes in the robot 
         * @summary Control Robot Brakes
         * @param {BrakesState} [brakesState] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setBrakesState(brakesState?: BrakesState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrakesState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setBrakesState(brakesState, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MovementBrakesApi.setBrakesState']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MovementBrakesApi - factory interface
 * @export
 */
export const MovementBrakesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MovementBrakesApiFp(configuration)
    return {
        /**
         * Engage Emergency braking system. > **⚠️ Warning:** This will immediately stop the robot and may cause damage to the robot or surrounding environment. 
         * @summary Engage Emergency Stop
         * @param {EngageEmergencyStopRequest} [engageEmergencyStopRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engageEmergencyStop(engageEmergencyStopRequest?: EngageEmergencyStopRequest, options?: any): AxiosPromise<void> {
            return localVarFp.engageEmergencyStop(engageEmergencyStopRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the current state of the robot brakes 
         * @summary Get Robot Brakes State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrakesState(options?: any): AxiosPromise<BrakesState> {
            return localVarFp.getBrakesState(options).then((request) => request(axios, basePath));
        },
        /**
         * Control Joint Brakes in the robot 
         * @summary Control Robot Brakes
         * @param {BrakesState} [brakesState] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBrakesState(brakesState?: BrakesState, options?: any): AxiosPromise<BrakesState> {
            return localVarFp.setBrakesState(brakesState, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MovementBrakesApi - object-oriented interface
 * @export
 * @class MovementBrakesApi
 * @extends {BaseAPI}
 */
export class MovementBrakesApi extends BaseAPI {
    /**
     * Engage Emergency braking system. > **⚠️ Warning:** This will immediately stop the robot and may cause damage to the robot or surrounding environment. 
     * @summary Engage Emergency Stop
     * @param {EngageEmergencyStopRequest} [engageEmergencyStopRequest] Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovementBrakesApi
     */
    public engageEmergencyStop(engageEmergencyStopRequest?: EngageEmergencyStopRequest, options?: AxiosRequestConfig) {
        return MovementBrakesApiFp(this.configuration).engageEmergencyStop(engageEmergencyStopRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the current state of the robot brakes 
     * @summary Get Robot Brakes State
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovementBrakesApi
     */
    public getBrakesState(options?: AxiosRequestConfig) {
        return MovementBrakesApiFp(this.configuration).getBrakesState(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Control Joint Brakes in the robot 
     * @summary Control Robot Brakes
     * @param {BrakesState} [brakesState] Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovementBrakesApi
     */
    public setBrakesState(brakesState?: BrakesState, options?: AxiosRequestConfig) {
        return MovementBrakesApiFp(this.configuration).setBrakesState(brakesState, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MovementPositionApi - axios parameter creator
 * @export
 */
export const MovementPositionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the current position of the robot arm
         * @summary Get Arm Position
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArmPosition: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/movement/position/arm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Control the position of the RO1 Robot arm. 
         * @summary Set Arm Position (Move)
         * @param {ArmPositionUpdateRequest} [armPositionUpdateRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setArmPosition: async (armPositionUpdateRequest?: ArmPositionUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/movement/position/arm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json; charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(armPositionUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MovementPositionApi - functional programming interface
 * @export
 */
export const MovementPositionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MovementPositionApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the current position of the robot arm
         * @summary Get Arm Position
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArmPosition(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CombinedArmPosition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArmPosition(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MovementPositionApi.getArmPosition']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Control the position of the RO1 Robot arm. 
         * @summary Set Arm Position (Move)
         * @param {ArmPositionUpdateRequest} [armPositionUpdateRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setArmPosition(armPositionUpdateRequest?: ArmPositionUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArmPositionUpdateEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setArmPosition(armPositionUpdateRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MovementPositionApi.setArmPosition']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MovementPositionApi - factory interface
 * @export
 */
export const MovementPositionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MovementPositionApiFp(configuration)
    return {
        /**
         * Get the current position of the robot arm
         * @summary Get Arm Position
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArmPosition(options?: any): AxiosPromise<CombinedArmPosition> {
            return localVarFp.getArmPosition(options).then((request) => request(axios, basePath));
        },
        /**
         * Control the position of the RO1 Robot arm. 
         * @summary Set Arm Position (Move)
         * @param {ArmPositionUpdateRequest} [armPositionUpdateRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setArmPosition(armPositionUpdateRequest?: ArmPositionUpdateRequest, options?: any): AxiosPromise<ArmPositionUpdateEvent> {
            return localVarFp.setArmPosition(armPositionUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MovementPositionApi - object-oriented interface
 * @export
 * @class MovementPositionApi
 * @extends {BaseAPI}
 */
export class MovementPositionApi extends BaseAPI {
    /**
     * Get the current position of the robot arm
     * @summary Get Arm Position
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovementPositionApi
     */
    public getArmPosition(options?: AxiosRequestConfig) {
        return MovementPositionApiFp(this.configuration).getArmPosition(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Control the position of the RO1 Robot arm. 
     * @summary Set Arm Position (Move)
     * @param {ArmPositionUpdateRequest} [armPositionUpdateRequest] Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovementPositionApi
     */
    public setArmPosition(armPositionUpdateRequest?: ArmPositionUpdateRequest, options?: AxiosRequestConfig) {
        return MovementPositionApiFp(this.configuration).setArmPosition(armPositionUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RoutineEditorRoutinesApi - axios parameter creator
 * @export
 */
export const RoutineEditorRoutinesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List routines defined in Routine Editor UI
         * @summary List Routines
         * @param {number} [limit] Maximum number of items to return
         * @param {number} [offset] Offset to start from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorRoutinesList: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/routine-editor/routines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get routine data by ID
         * @summary Get Routine
         * @param {string} routineId Routine ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorRoutinesLoad: async (routineId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routineId' is not null or undefined
            assertParamExists('routineEditorRoutinesLoad', 'routineId', routineId)
            const localVarPath = `/api/v1/routine-editor/routines/{routine_id}`
                .replace(`{${"routine_id"}}`, encodeURIComponent(String(routineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Pause a routine
         * @summary Pause Routine
         * @param {string} routineId Routine ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorRoutinesPause: async (routineId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routineId' is not null or undefined
            assertParamExists('routineEditorRoutinesPause', 'routineId', routineId)
            const localVarPath = `/api/v1/routine-editor/routines/{routine_id}/pause`
                .replace(`{${"routine_id"}}`, encodeURIComponent(String(routineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Play a routine
         * @summary Play Routine
         * @param {string} routineId Routine ID
         * @param {PlayRoutineRequest} [playRoutineRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorRoutinesPlay: async (routineId: string, playRoutineRequest?: PlayRoutineRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routineId' is not null or undefined
            assertParamExists('routineEditorRoutinesPlay', 'routineId', routineId)
            const localVarPath = `/api/v1/routine-editor/routines/{routine_id}/play`
                .replace(`{${"routine_id"}}`, encodeURIComponent(String(routineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json; charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(playRoutineRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stop running routine and all ongoing motions
         * @summary Stop Routine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorRoutinesStop: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/routine-editor/stop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoutineEditorRoutinesApi - functional programming interface
 * @export
 */
export const RoutineEditorRoutinesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoutineEditorRoutinesApiAxiosParamCreator(configuration)
    return {
        /**
         * List routines defined in Routine Editor UI
         * @summary List Routines
         * @param {number} [limit] Maximum number of items to return
         * @param {number} [offset] Offset to start from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routineEditorRoutinesList(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoutinesPaginatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routineEditorRoutinesList(limit, offset, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoutineEditorRoutinesApi.routineEditorRoutinesList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Get routine data by ID
         * @summary Get Routine
         * @param {string} routineId Routine ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routineEditorRoutinesLoad(routineId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Routine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routineEditorRoutinesLoad(routineId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoutineEditorRoutinesApi.routineEditorRoutinesLoad']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Pause a routine
         * @summary Pause Routine
         * @param {string} routineId Routine ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routineEditorRoutinesPause(routineId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routineEditorRoutinesPause(routineId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoutineEditorRoutinesApi.routineEditorRoutinesPause']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Play a routine
         * @summary Play Routine
         * @param {string} routineId Routine ID
         * @param {PlayRoutineRequest} [playRoutineRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routineEditorRoutinesPlay(routineId: string, playRoutineRequest?: PlayRoutineRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routineEditorRoutinesPlay(routineId, playRoutineRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoutineEditorRoutinesApi.routineEditorRoutinesPlay']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Stop running routine and all ongoing motions
         * @summary Stop Routine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routineEditorRoutinesStop(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routineEditorRoutinesStop(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoutineEditorRoutinesApi.routineEditorRoutinesStop']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RoutineEditorRoutinesApi - factory interface
 * @export
 */
export const RoutineEditorRoutinesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoutineEditorRoutinesApiFp(configuration)
    return {
        /**
         * List routines defined in Routine Editor UI
         * @summary List Routines
         * @param {number} [limit] Maximum number of items to return
         * @param {number} [offset] Offset to start from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorRoutinesList(limit?: number, offset?: number, options?: any): AxiosPromise<RoutinesPaginatedResponse> {
            return localVarFp.routineEditorRoutinesList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Get routine data by ID
         * @summary Get Routine
         * @param {string} routineId Routine ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorRoutinesLoad(routineId: string, options?: any): AxiosPromise<Routine> {
            return localVarFp.routineEditorRoutinesLoad(routineId, options).then((request) => request(axios, basePath));
        },
        /**
         * Pause a routine
         * @summary Pause Routine
         * @param {string} routineId Routine ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorRoutinesPause(routineId: string, options?: any): AxiosPromise<void> {
            return localVarFp.routineEditorRoutinesPause(routineId, options).then((request) => request(axios, basePath));
        },
        /**
         * Play a routine
         * @summary Play Routine
         * @param {string} routineId Routine ID
         * @param {PlayRoutineRequest} [playRoutineRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorRoutinesPlay(routineId: string, playRoutineRequest?: PlayRoutineRequest, options?: any): AxiosPromise<void> {
            return localVarFp.routineEditorRoutinesPlay(routineId, playRoutineRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Stop running routine and all ongoing motions
         * @summary Stop Routine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorRoutinesStop(options?: any): AxiosPromise<void> {
            return localVarFp.routineEditorRoutinesStop(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoutineEditorRoutinesApi - object-oriented interface
 * @export
 * @class RoutineEditorRoutinesApi
 * @extends {BaseAPI}
 */
export class RoutineEditorRoutinesApi extends BaseAPI {
    /**
     * List routines defined in Routine Editor UI
     * @summary List Routines
     * @param {number} [limit] Maximum number of items to return
     * @param {number} [offset] Offset to start from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutineEditorRoutinesApi
     */
    public routineEditorRoutinesList(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return RoutineEditorRoutinesApiFp(this.configuration).routineEditorRoutinesList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get routine data by ID
     * @summary Get Routine
     * @param {string} routineId Routine ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutineEditorRoutinesApi
     */
    public routineEditorRoutinesLoad(routineId: string, options?: AxiosRequestConfig) {
        return RoutineEditorRoutinesApiFp(this.configuration).routineEditorRoutinesLoad(routineId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Pause a routine
     * @summary Pause Routine
     * @param {string} routineId Routine ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutineEditorRoutinesApi
     */
    public routineEditorRoutinesPause(routineId: string, options?: AxiosRequestConfig) {
        return RoutineEditorRoutinesApiFp(this.configuration).routineEditorRoutinesPause(routineId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Play a routine
     * @summary Play Routine
     * @param {string} routineId Routine ID
     * @param {PlayRoutineRequest} [playRoutineRequest] Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutineEditorRoutinesApi
     */
    public routineEditorRoutinesPlay(routineId: string, playRoutineRequest?: PlayRoutineRequest, options?: AxiosRequestConfig) {
        return RoutineEditorRoutinesApiFp(this.configuration).routineEditorRoutinesPlay(routineId, playRoutineRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stop running routine and all ongoing motions
     * @summary Stop Routine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutineEditorRoutinesApi
     */
    public routineEditorRoutinesStop(options?: AxiosRequestConfig) {
        return RoutineEditorRoutinesApiFp(this.configuration).routineEditorRoutinesStop(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RoutineEditorVariablesApi - axios parameter creator
 * @export
 */
export const RoutineEditorVariablesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns current state of a variable
         * @summary Get Variable Value
         * @param {string} variableId Variable ID to observe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorVariablesLoad: async (variableId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'variableId' is not null or undefined
            assertParamExists('routineEditorVariablesLoad', 'variableId', variableId)
            const localVarPath = `/api/v1/routine-editor/variables/{variable_id}`
                .replace(`{${"variable_id"}}`, encodeURIComponent(String(variableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the value of a variable
         * @summary Update Variable Value
         * @param {string} variableId Variable ID to observe
         * @param {RuntimeVariable} [runtimeVariable] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorVariablesUpdate: async (variableId: string, runtimeVariable?: RuntimeVariable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'variableId' is not null or undefined
            assertParamExists('routineEditorVariablesUpdate', 'variableId', variableId)
            const localVarPath = `/api/v1/routine-editor/variables/{variable_id}`
                .replace(`{${"variable_id"}}`, encodeURIComponent(String(variableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json; charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(runtimeVariable, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoutineEditorVariablesApi - functional programming interface
 * @export
 */
export const RoutineEditorVariablesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoutineEditorVariablesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns current state of a variable
         * @summary Get Variable Value
         * @param {string} variableId Variable ID to observe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routineEditorVariablesLoad(variableId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeVariable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routineEditorVariablesLoad(variableId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoutineEditorVariablesApi.routineEditorVariablesLoad']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Update the value of a variable
         * @summary Update Variable Value
         * @param {string} variableId Variable ID to observe
         * @param {RuntimeVariable} [runtimeVariable] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routineEditorVariablesUpdate(variableId: string, runtimeVariable?: RuntimeVariable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeVariable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routineEditorVariablesUpdate(variableId, runtimeVariable, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoutineEditorVariablesApi.routineEditorVariablesUpdate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RoutineEditorVariablesApi - factory interface
 * @export
 */
export const RoutineEditorVariablesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoutineEditorVariablesApiFp(configuration)
    return {
        /**
         * Returns current state of a variable
         * @summary Get Variable Value
         * @param {string} variableId Variable ID to observe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorVariablesLoad(variableId: string, options?: any): AxiosPromise<RuntimeVariable> {
            return localVarFp.routineEditorVariablesLoad(variableId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the value of a variable
         * @summary Update Variable Value
         * @param {string} variableId Variable ID to observe
         * @param {RuntimeVariable} [runtimeVariable] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorVariablesUpdate(variableId: string, runtimeVariable?: RuntimeVariable, options?: any): AxiosPromise<RuntimeVariable> {
            return localVarFp.routineEditorVariablesUpdate(variableId, runtimeVariable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoutineEditorVariablesApi - object-oriented interface
 * @export
 * @class RoutineEditorVariablesApi
 * @extends {BaseAPI}
 */
export class RoutineEditorVariablesApi extends BaseAPI {
    /**
     * Returns current state of a variable
     * @summary Get Variable Value
     * @param {string} variableId Variable ID to observe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutineEditorVariablesApi
     */
    public routineEditorVariablesLoad(variableId: string, options?: AxiosRequestConfig) {
        return RoutineEditorVariablesApiFp(this.configuration).routineEditorVariablesLoad(variableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the value of a variable
     * @summary Update Variable Value
     * @param {string} variableId Variable ID to observe
     * @param {RuntimeVariable} [runtimeVariable] Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutineEditorVariablesApi
     */
    public routineEditorVariablesUpdate(variableId: string, runtimeVariable?: RuntimeVariable, options?: AxiosRequestConfig) {
        return RoutineEditorVariablesApiFp(this.configuration).routineEditorVariablesUpdate(variableId, runtimeVariable, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SpaceApi - axios parameter creator
 * @export
 */
export const SpaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List Planes
         * @summary List Planes
         * @param {number} [limit] Maximum number of items to return
         * @param {number} [offset] Offset to start from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlanes: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/space/planes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List Positions
         * @summary List Positions
         * @param {number} [limit] Maximum number of items to return
         * @param {number} [offset] Offset to start from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPosition: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/space/positions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpaceApi - functional programming interface
 * @export
 */
export const SpaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpaceApiAxiosParamCreator(configuration)
    return {
        /**
         * List Planes
         * @summary List Planes
         * @param {number} [limit] Maximum number of items to return
         * @param {number} [offset] Offset to start from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPlanes(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanesPaginatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPlanes(limit, offset, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SpaceApi.listPlanes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * List Positions
         * @summary List Positions
         * @param {number} [limit] Maximum number of items to return
         * @param {number} [offset] Offset to start from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPosition(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanesPaginatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPosition(limit, offset, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SpaceApi.listPosition']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SpaceApi - factory interface
 * @export
 */
export const SpaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpaceApiFp(configuration)
    return {
        /**
         * List Planes
         * @summary List Planes
         * @param {number} [limit] Maximum number of items to return
         * @param {number} [offset] Offset to start from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlanes(limit?: number, offset?: number, options?: any): AxiosPromise<PlanesPaginatedResponse> {
            return localVarFp.listPlanes(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * List Positions
         * @summary List Positions
         * @param {number} [limit] Maximum number of items to return
         * @param {number} [offset] Offset to start from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPosition(limit?: number, offset?: number, options?: any): AxiosPromise<PlanesPaginatedResponse> {
            return localVarFp.listPosition(limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpaceApi - object-oriented interface
 * @export
 * @class SpaceApi
 * @extends {BaseAPI}
 */
export class SpaceApi extends BaseAPI {
    /**
     * List Planes
     * @summary List Planes
     * @param {number} [limit] Maximum number of items to return
     * @param {number} [offset] Offset to start from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceApi
     */
    public listPlanes(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return SpaceApiFp(this.configuration).listPlanes(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List Positions
     * @summary List Positions
     * @param {number} [limit] Maximum number of items to return
     * @param {number} [offset] Offset to start from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceApi
     */
    public listPosition(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return SpaceApiFp(this.configuration).listPosition(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StatusControlApi - axios parameter creator
 * @export
 */
export const StatusControlApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the system which is controlling the robot
         * @summary Get Control Mode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigurationStateControl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/status/control-mode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusControlApi - functional programming interface
 * @export
 */
export const StatusControlApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatusControlApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the system which is controlling the robot
         * @summary Get Control Mode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfigurationStateControl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotControlMode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfigurationStateControl(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StatusControlApi.getConfigurationStateControl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * StatusControlApi - factory interface
 * @export
 */
export const StatusControlApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatusControlApiFp(configuration)
    return {
        /**
         * Get the system which is controlling the robot
         * @summary Get Control Mode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigurationStateControl(options?: any): AxiosPromise<RobotControlMode> {
            return localVarFp.getConfigurationStateControl(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatusControlApi - object-oriented interface
 * @export
 * @class StatusControlApi
 * @extends {BaseAPI}
 */
export class StatusControlApi extends BaseAPI {
    /**
     * Get the system which is controlling the robot
     * @summary Get Control Mode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusControlApi
     */
    public getConfigurationStateControl(options?: AxiosRequestConfig) {
        return StatusControlApiFp(this.configuration).getConfigurationStateControl(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StatusHealthApi - axios parameter creator
 * @export
 */
export const StatusHealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the current health of the robot
         * @summary Get Robot Health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/status/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusHealthApi - functional programming interface
 * @export
 */
export const StatusHealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatusHealthApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the current health of the robot
         * @summary Get Robot Health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusHealthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealth(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StatusHealthApi.getHealth']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * StatusHealthApi - factory interface
 * @export
 */
export const StatusHealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatusHealthApiFp(configuration)
    return {
        /**
         * Get the current health of the robot
         * @summary Get Robot Health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth(options?: any): AxiosPromise<StatusHealthResponse> {
            return localVarFp.getHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatusHealthApi - object-oriented interface
 * @export
 * @class StatusHealthApi
 * @extends {BaseAPI}
 */
export class StatusHealthApi extends BaseAPI {
    /**
     * Get the current health of the robot
     * @summary Get Robot Health
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusHealthApi
     */
    public getHealth(options?: AxiosRequestConfig) {
        return StatusHealthApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
    }
}



