import cx from 'classnames';

import type { ComponentColor } from '../../utility';

export const BADGE_COLOR_CLASSES: Record<ComponentColor, string> = {
  Orange: cx(
    'tw-bg-orange-20',
    'tw-text-orange-90',
    'dark:tw-bg-orange-90',
    'dark:tw-text-orange-10',
  ),
  Red: cx(
    'tw-bg-red-20',
    'tw-text-red-90',
    'dark:tw-bg-red-90',
    'dark:tw-text-red-10',
  ),
  Green: cx(
    'tw-bg-green-20',
    'tw-text-green-90',
    'dark:tw-bg-green-80',
    'dark:tw-text-green-10',
  ),
  Blue: cx(
    'tw-bg-blue-20',
    'tw-text-blue-90',
    'dark:tw-bg-blue-80',
    'dark:tw-text-blue-10',
  ),
  Primary: cx(
    'tw-border',
    'tw-border-divider-primary',
    'tw-text-label-primary',
    'dark:tw-text-label-tertiary',
  ),
};
