import * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';
import { OnRobotConnectionConfiguration } from '@sb/integrations/implementations/gripper-general/types/Configuration';

import { OR2FG7_DEFAULT_TCP_TRANSFORM, OR2FG7_SHORTNAME } from '../constants';

import { OnRobot2FG7FingerConfiguration } from './OnRobot2FG7FingerConfiguration';

export const OnRobot2FG7Configuration = zod.object({
  kind: zod.literal('OnRobot2FG7'),
  name: zod.string().default(OR2FG7_SHORTNAME),
  fingerConfiguration: OnRobot2FG7FingerConfiguration,
  connectionOptions: OnRobotConnectionConfiguration,
  tcpTransform: CartesianPose.default(OR2FG7_DEFAULT_TCP_TRANSFORM),
});

export type OnRobot2FG7Configuration = zod.infer<
  typeof OnRobot2FG7Configuration
>;

export const ON_ROBOT_2FG7_GRIPPERS_CONFIGURATION_DEFAULT =
  OnRobot2FG7Configuration.parse({ kind: 'OnRobot2FG7' });
