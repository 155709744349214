import cx from 'classnames';

import type { IconKind } from '../../icons';
import { Icon } from '../../icons';

export interface MenuLinkItemProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  iconKind?: IconKind;
  secondaryIconKind?: IconKind;
  iconKindClassName?: string;
}

export function MenuLinkItem({
  className,
  iconKind,
  secondaryIconKind,
  iconKindClassName,
  children,
  ...rest
}: MenuLinkItemProps) {
  return (
    <li>
      <a
        {...rest}
        className={cx(
          className,
          'tw-flex',
          'tw-flex-none',
          'tw-gap-8',
          'tw-items-center',
          'tw-h-44',
          'tw-text-17',
          'tw-icon-22',
          'tw-px-16',
          'aria-disabled:tw-cursor-not-allowed',
          'aria-disabled:tw-text-label-quaternary',
        )}
      >
        {secondaryIconKind && (
          <Icon kind={secondaryIconKind} className={iconKindClassName} />
        )}
        <div className={cx('tw-flex-1', 'tw-text-ellipsis')}>{children}</div>
        {iconKind && <Icon kind={iconKind} className={iconKindClassName} />}
      </a>
    </li>
  );
}
