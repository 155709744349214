import type { StepValidator } from './types';

export const stepConfiguredValidator: StepValidator = (
  _step,
  stepConfiguration,
) => {
  if (!stepConfiguration?.args) {
    return {
      kind: 'missingConfiguration',
      message: 'This step needs to be configured.',
    };
  }

  return undefined;
};
