import type { Step } from '@sb/remote-control/types';

import type { StepValidator } from '../types';

export const runInBackgroundValidator: StepValidator = (step) => {
  if (step.stepKind !== 'RunInBackground') {
    return undefined;
  }

  const INVALID_CHILD_KINDS: Step.Kind[] = [
    'AddOffset',
    'MoveArmTo',
    'PressButton',
    'WaitForConfirmation',
  ];

  const hasInvalidChild = (parentStep: Step.ConvertedSummary): boolean => {
    return parentStep.steps.some(
      (childStep) =>
        INVALID_CHILD_KINDS.includes(childStep.stepKind) ||
        hasInvalidChild(childStep),
    );
  };

  if (hasInvalidChild(step)) {
    return {
      kind: 'invalidConfiguration',
      message:
        'Run in Background steps may not contain Add Offset, Move Arm, Press Button or Wait For Confirmation steps.',
    };
  }

  return undefined;
};
