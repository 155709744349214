import type { ExternalControlSettings } from '@sb/routine-runner';

import { getExternalControlSettingsService } from './getExternalControlSettingsService';

export async function updateExternalControlSettings(
  robotID: string,
  externalControlSettings: ExternalControlSettings,
): Promise<void> {
  // create method runs create-or-patch in safetySettings service
  await getExternalControlSettingsService().create({
    robotID,
    ...externalControlSettings,
  });
}
