import { capitalize, lowerCase } from 'lodash';

import { Robot, type Equipment } from '@sb/remote-control/types';
import {
  EXTERNAL_TO_ROBOT_PORT_NAMES,
  ROBOT_TO_EXTERNAL_PORT_NAMES,
  SAFEGUARD_KIND_NAMES,
  SAFEGUARD_PAIR_PORTS,
  type ExternalControlSettings,
  type SafeguardRule,
} from '@sb/routine-runner';

import { generateIOPort } from './generateIOPort';

function getCustomIOPorts(
  equipment: Equipment.ConvertedResponse[],
): Robot.IOPort[] {
  return equipment.flatMap(({ config }) =>
    config.kind === 'CustomIO'
      ? config.ports.map<Robot.IOPort>((p) => ({
          ...p,
          assignedTo: [
            Robot.IO_PORT_ASSIGN_TO_SAFETY_IO,
            Robot.IO_PORT_ASSIGN_TO_EXTERNAL_CONTROL,
            '',
          ].includes(config.name)
            ? 'IO Device'
            : config.name,
        }))
      : [],
  );
}

function getSafetyIOPorts(safeguardRules: SafeguardRule[]): Robot.IOPort[] {
  const ports: Robot.IOPort[] = [];

  for (const rule of safeguardRules) {
    if (rule.kind === 'none') {
      continue;
    }

    for (const port of SAFEGUARD_PAIR_PORTS[rule.pair]) {
      let highSignalName = 'High';
      let lowSignalName = 'Low';

      switch (rule.kind) {
        case 'estop': {
          highSignalName = 'Run';
          lowSignalName = 'Stop';
          break;
        }
        case 'slow': {
          highSignalName = 'Full Speed';
          lowSignalName = 'Slow Speed';
          break;
        }
        case 'pausehigh': {
          highSignalName = 'Pause';
          lowSignalName = 'Run';
          break;
        }
        case 'pauselow': {
          highSignalName = 'Run';
          lowSignalName = 'Pause';
          break;
        }
        case 'reset': {
          highSignalName = 'Reset';
          lowSignalName = 'None';
          break;
        }
        default:
          break;
      }

      ports.push({
        kind: 'Input',
        label: 'IN',
        name: SAFEGUARD_KIND_NAMES[rule.kind],
        port,
        safeguardKind: rule.kind,
        assignedTo: Robot.IO_PORT_ASSIGN_TO_SAFETY_IO,
        highSignalName,
        lowSignalName,
      });
    }
  }

  return ports;
}

function getExternalControlIOPorts(
  externalControlSettings: ExternalControlSettings | undefined,
): Robot.IOPort[] {
  const ports: Robot.IOPort[] = [];

  if (
    externalControlSettings &&
    externalControlSettings.isEnabled &&
    externalControlSettings.is24VIOEnabled
  ) {
    for (const [key, portName] of Object.entries(
      externalControlSettings.ioPorts,
    )) {
      const name = capitalize(lowerCase(key));

      ports.push({
        kind: portName.startsWith('Input') ? 'Input' : 'Output',
        name,
        port: Number.parseInt(portName.replace(/[^\d]/g, ''), 10),
        assignedTo: Robot.IO_PORT_ASSIGN_TO_EXTERNAL_CONTROL,
        highSignalName: name,
        lowSignalName: 'Low',
      });
    }
  }

  return ports;
}

export function getIOPorts<T extends Robot.IOPort>(
  kind: T['kind'],
  robotIOPorts: Robot.IOPort[] | null,
  safeguardRules: SafeguardRule[],
  externalControlSettings: ExternalControlSettings | undefined,
  equipment: Equipment.ConvertedResponse[],
): T[] {
  const portNames =
    kind === 'Input'
      ? EXTERNAL_TO_ROBOT_PORT_NAMES
      : ROBOT_TO_EXTERNAL_PORT_NAMES;

  const allPorts = [
    // ports assigned in safety settings
    ...getSafetyIOPorts(safeguardRules),
    // ports assigned in external control
    ...getExternalControlIOPorts(externalControlSettings),
    // ports assigned in equipment manager
    ...getCustomIOPorts(equipment),
    // ports defined in IO Manager
    ...(robotIOPorts?.filter((p) => !p.assignedTo && !p.safeguardKind) ?? []),
  ];

  return portNames
    .map((_, index) => {
      return (
        allPorts.find((p) => p.kind === kind && p.port === index + 1) ??
        generateIOPort(kind, index)
      );
    })
    .filter((p): p is T => p.kind === kind);
}
