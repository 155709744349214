import * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';
import { OnRobotConnectionConfiguration } from '@sb/integrations/implementations/gripper-general/types/Configuration';

import { OR3FG15_DEFAULT_TCP_TRANSFORM, OR3FG15_SHORTNAME } from '../constants';

import { OnRobot3FG15FingerConfiguration } from './OnRobot3FG15FingerConfiguration';

export const OnRobot3FG15Configuration = zod.object({
  kind: zod.literal('OnRobot3FG15'),
  name: zod.string().default(OR3FG15_SHORTNAME),
  fingerConfiguration: OnRobot3FG15FingerConfiguration,
  connectionOptions: OnRobotConnectionConfiguration,
  tcpTransform: CartesianPose.default(OR3FG15_DEFAULT_TCP_TRANSFORM),
});

export type OnRobot3FG15Configuration = zod.infer<
  typeof OnRobot3FG15Configuration
>;

export const ON_ROBOT_3FG15_GRIPPERS_CONFIGURATION_DEFAULT =
  OnRobot3FG15Configuration.parse({ kind: 'OnRobot3FG15' });
