import type { Placement } from '@popperjs/core';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import {
  DesignSystemContextProvider,
  usePortalContainer,
} from '../DesignSystemContext';
import { useClickOutside } from '../useClickOutside';

export type { Placement };

export interface PopperProps extends React.HTMLAttributes<HTMLDivElement> {
  content: React.ReactNode;
  isOpen: boolean;
  placement?: Placement | undefined;
  fallbackPlacements?: Placement[];
  onClose: () => void;
  offset?: number;
  skidding?: number;
  isDark?: boolean;
}

/** Override popperjs fallbacks for some placements, so longer dropdowns get more space to fit on the page */
const FALLBACK_PLACEMENTS: { [placement in Placement]?: Placement[] } = {
  'bottom-end': ['top-end', 'left-start', 'right-start'],
  'bottom-start': ['top-start', 'right-start', 'left-start'],
  bottom: ['top', 'right-start', 'left-start'],
  'top-end': ['bottom-end', 'left-end', 'right-end'],
  'top-start': ['bottom-start', 'right-end', 'left-end'],
  top: ['bottom', 'right-end', 'left-end'],
};

export function Popper({
  children,
  content,
  isOpen,
  placement = 'bottom',
  fallbackPlacements = FALLBACK_PLACEMENTS[placement],
  onClose,
  onClick,
  className,
  offset = 4,
  skidding = 0,
  isDark,
  ...rest
}: PopperProps) {
  const parentContainerElement = usePortalContainer();

  // The container holds this popup _plus any descendant popups_ (e.g. sub menus).
  // Descendants access this container through `DesignSystemContext`.
  // This lets us use the DOM structure for the clickOutside hook to work as expected.
  const [containerElement, setContainerElement] =
    useState<HTMLDivElement | null>(null);

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  useClickOutside([containerElement, referenceElement], onClose);

  const {
    styles: popperStyles,
    attributes,
    forceUpdate,
  } = usePopper(referenceElement, popperElement, {
    placement,
    strategy:
      'fixed' /* avoids dropdown triggering overflow on document.body */,
    modifiers: [
      { name: 'flip', options: { fallbackPlacements } },
      { name: 'offset', options: { offset: [skidding, offset] } },
      { name: 'preventOverflow', options: { padding: 10 } },
      { name: 'eventListeners', enabled: isOpen },
    ],
  });

  useEffect(() => {
    if (content && forceUpdate) {
      queueMicrotask(() => {
        forceUpdate();
      });
    }
  }, [content, forceUpdate]);

  // close the popper if user clicks on an enabled button inside it
  // (you can override this by adding `event.stopPropagation()` in the button click handler)
  const handleClickPopper: React.MouseEventHandler = (event) => {
    let target: EventTarget | null = event.target; // eslint-disable-line prefer-destructuring

    while (target) {
      if (target instanceof HTMLButtonElement) {
        if (!target.disabled) onClose();
        target = null;
      } else if (target instanceof Element && target.role === 'button') {
        if (target.ariaDisabled !== 'true') onClose();
        target = null;
      } else if (target instanceof Element) {
        target = target.parentElement;
      } else {
        target = null;
      }
    }
  };

  /* eslint-disable jsx-a11y/no-static-element-interactions */

  return (
    <>
      <div
        role={onClick ? 'button' : undefined}
        aria-pressed={onClick ? isOpen : undefined}
        {...rest}
        className={cx(className, 'aria-pressed:tw-opacity-40')}
        onClick={onClick}
        ref={setReferenceElement}
      >
        {children}
      </div>

      {createPortal(
        <div
          ref={setContainerElement}
          className={cx('tw-contents', isDark && 'tw-dark')}
        >
          <DesignSystemContextProvider portalContainer={containerElement}>
            <div
              ref={setPopperElement}
              className={cx(
                // override browser's [hidden] rule so content fades out
                '[&[hidden]]:tw-block',
                '[&[hidden]]:tw-invisible',
                '[&[hidden]]:tw-opacity-0',
                'data-[popper-reference-hidden=true]:tw-invisible',
                'data-[popper-reference-hidden=true]:tw-opacity-0',
                'tw-transition-opacity',
                'tw-z-40',
              )}
              onClick={handleClickPopper}
              style={popperStyles.popper}
              {...attributes.popper}
              hidden={!isOpen}
            >
              {content}
            </div>
          </DesignSystemContextProvider>
        </div>,
        parentContainerElement,
      )}
    </>
  );
}
