import type { StepValidator } from '../types';

const checkBothGrippersDefines: StepValidator = (_step, stepConfiguration) => {
  if (stepConfiguration?.stepKind !== 'ActuateGripper') return undefined;
  const { args } = stepConfiguration;
  if (!args) return undefined;

  if (args.selectedGripper === 'none') {
    return {
      kind: 'invalidConfiguration',
      message: 'Select a gripper',
      fieldId: 'selectedGripper',
    };
  }

  return undefined;
};

export const actuateGripperValidators = [checkBothGrippersDefines];
