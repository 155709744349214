import type { GripperState } from '@sb/integrations/implementations/gripper-general';
import type { RoutineRunnerState } from '@sb/routine-runner';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

export type GripperKind = NonNullable<GripperState>['kind'];

const getGripperState =
  <K extends GripperKind = GripperKind>(kind?: K) =>
  (state: RoutineRunnerState | null): (GripperState & { kind: K }) | null => {
    if (!state) {
      return null;
    }

    const {
      kinematicState: { gripperState },
    } = state;

    if (kind && gripperState?.kind !== kind) {
      return null;
    }

    return gripperState as GripperState & { kind: K };
  };

export function useRobotGripperState<K extends GripperKind = GripperKind>(
  args: UseRoutineRunnerHandleArguments,
  kind?: K,
): (GripperState & { kind: K }) | null {
  return useRobotState(args, getGripperState(kind));
}

export default useRobotGripperState;
