import { mapPaginated } from '@sb/feathers-types';
import type { Robot } from '@sb/remote-control/types';

import { getAllEnabledEquipment } from '../equipment';
import { getExternalControlSettings } from '../external-control-settings';
import { getSafetySettings } from '../safety-settings';

import { convertRobotResponse, getRobotsService } from './utils';

export async function getRobot(
  robotID: string,
): Promise<Robot.ConvertedResponse | null> {
  const [response, safetySettings, equipment, externalControlSettings] =
    await Promise.all([
      getRobotsService().find({
        query: { robotID, $limit: 1 },
      }),
      getSafetySettings(robotID),
      getAllEnabledEquipment(),
      getExternalControlSettings(robotID),
    ]);

  const robot =
    mapPaginated(response, (data) =>
      convertRobotResponse(
        data,
        safetySettings.safeguardRules,
        externalControlSettings,
        equipment,
      ),
    )[0] ?? null;

  return robot;
}
