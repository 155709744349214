import { EwellixLiftTLTState } from '../implementations/EwellixLiftTLT/types';
import { GripperStateNonNull } from '../implementations/gripper-general/GripperState';

/**
 * Device state is the current state of the device.
 */
export const DeviceStateRegistry = [
  GripperStateNonNull,
  EwellixLiftTLTState,
] as const;

export const DynamicBaseStateRegistry = [EwellixLiftTLTState] as const;
