import * as zod from 'zod';

import { CameraIntegration } from '@sb/integrations/types/cameraTypes';
import {
  ClassifyClass,
  DEFAULT_REGION_OF_INTEREST,
  RegionOfInterest,
} from '@sb/routine-runner';

export namespace ClassifyStep {
  export const Arguments = zod.object({
    argumentKind: zod.literal('Classify'),
    camera: CameraIntegration,
    regionOfInterest: RegionOfInterest.default(DEFAULT_REGION_OF_INTEREST),
    method: zod
      .object({
        kind: zod.literal('templateMatch'),
      })
      .default({ kind: 'templateMatch' }),
    classes: zod.array(ClassifyClass).default([]),
    confidence: zod.number().default(0.5),
    fallbackValue: zod.string().default('No class'),
  });

  export type Arguments = zod.infer<typeof Arguments>;
}
