import type { EquipmentData } from '@sb/feathers-types';
import { applyCompoundPose } from '@sb/geometry';
import {
  OR_DUAL_QUICK_CHANGER_PRIMARY_POSE,
  OR_DUAL_QUICK_CHANGER_SECONDARY_POSE,
} from '@sb/integrations/implementations/OnRobotDualQuickChanger/constants';
import { DeviceConfiguration } from '@sb/integrations/types';
import type { Equipment } from '@sb/remote-control/types';

// apply additional transforms to dual qc primary/secondary gripper defaults
function fixDualQCDefaultTCPTransform(
  config: any,
  parsedConfig: DeviceConfiguration,
) {
  if (parsedConfig.kind === 'OnRobotDualQuickChanger') {
    if (
      'tcpTransform' in parsedConfig.grippers.primary &&
      !config.grippers?.primary?.tcpTransform
    ) {
      // eslint-disable-next-line no-param-reassign
      parsedConfig.grippers.primary.tcpTransform = applyCompoundPose(
        parsedConfig.grippers.primary.tcpTransform,
        OR_DUAL_QUICK_CHANGER_PRIMARY_POSE,
      );
    }

    if (
      'tcpTransform' in parsedConfig.grippers.secondary &&
      !config.grippers?.secondary?.tcpTransform
    ) {
      // eslint-disable-next-line no-param-reassign
      parsedConfig.grippers.secondary.tcpTransform = applyCompoundPose(
        parsedConfig.grippers.secondary.tcpTransform,
        OR_DUAL_QUICK_CHANGER_SECONDARY_POSE,
      );
    }
  }
}

export function convertEquipmentResponse(
  equipment: EquipmentData,
): Equipment.ConvertedResponse {
  const parsedConfig = DeviceConfiguration.safeParse(equipment.config);

  if (parsedConfig.success) {
    fixDualQCDefaultTCPTransform(equipment.config, parsedConfig.data);

    return {
      id: equipment.id,
      config: parsedConfig.data,
      isEnabled: equipment.isEnabled,
    };
  }

  return {
    id: equipment.id,
    config: {
      kind: 'InvalidConfiguration',
      name: equipment.config.name ?? equipment.config.kind,
      invalidConfiguration: equipment.config,
    },
    isEnabled: false,
  };
}
