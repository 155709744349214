import type { Equipment, Integrations } from '@sb/remote-control/types';

import type { OnRobotDualQuickChangerCompatibleGripper } from '../../implementations/OnRobotDualQuickChanger/types';
import type { DeviceConfiguration, DeviceKind } from '../../types';
import { isEndEffector } from '../util';

const onRobotDualCompatableEndEffectors = [
  'OnRobot3FG15',
  'OnRobot2FG7',
  'NoGripper',
] as OnRobotDualQuickChangerCompatibleGripper['kind'][];

export const isDualCompatibleEndEffector = (kind: DeviceKind) => {
  return onRobotDualCompatableEndEffectors.some((k) => k === kind);
};

export const getEndEffector = (equipment: DeviceConfiguration[]) => {
  return equipment.find((item) => isEndEffector(item.kind));
};

export const canAddIntegration = (
  kind: DeviceKind,
  existingEquipment: Equipment.ConvertedResponse[],
  getEquipmentByKind: Integrations.GetEquipmentByKind,
): boolean => {
  const proposedIntegration = getEquipmentByKind(kind);

  if (proposedIntegration.canAddAsNewIntegration) {
    const existingIntMapped = existingEquipment
      .filter((item) => item.isEnabled)
      .map((item) => getEquipmentByKind(item.config.kind));

    return proposedIntegration.canAddAsNewIntegration(existingIntMapped);
  }

  return true;
};

export const getOnRobotDualCompatableEndEffectorsList =
  (): OnRobotDualQuickChangerCompatibleGripper['kind'][] => {
    return onRobotDualCompatableEndEffectors;
  };
