import type { StepValidator } from '../types';

export const runSkillValidator: StepValidator = (_step, stepConfiguration) => {
  if (stepConfiguration?.stepKind !== 'RunSkill' || !stepConfiguration.args) {
    return undefined;
  }

  if (!stepConfiguration.args.skill) {
    return {
      kind: 'invalidConfiguration',
      message: 'Skill not selected',
    };
  }

  if (stepConfiguration.args.botIDs.length === 0) {
    return {
      kind: 'invalidConfiguration',
      message: 'No robots selected',
    };
  }

  if (stepConfiguration.args.cameraIDs.length === 0) {
    return {
      kind: 'invalidConfiguration',
      message: 'No cameras selected',
    };
  }

  return undefined;
};
