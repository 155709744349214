import { DECORATOR_STEP_LIST } from '../constants';

import type { StepValidator } from './types';

/** Is decorator step that doesn't have nested steps. */
export const decoratorStepValidator: StepValidator = (step) => {
  if (DECORATOR_STEP_LIST.includes(step.stepKind) && step.steps.length === 0) {
    return {
      kind: 'invalidConfiguration',
      message: 'Tap row below to add step.',
    };
  }

  return undefined;
};
