import cx from 'classnames';

import {
  useComponentColorClassName,
  type ComponentColor,
  type ComponentColorOrDefault,
} from '../../../utility';
import { calculateClampedPercent } from '../util';

import { ValueLabel } from './ValueLabel';

const COLOR_CLASSES: Record<ComponentColor, string> = {
  Orange: 'tw-border-orange tw-from-orange-20 dark:tw-from-orange-80',
  Red: 'tw-border-red tw-from-red-20 dark:tw-from-red-80',
  Green: 'tw-border-green tw-from-green-20 dark:tw-from-green-70',
  Blue: 'tw-border-blue tw-from-blue-20 dark:tw-from-blue-70',
  Primary: '',
};

export interface FilledGaugeProps {
  min: number;
  max: number;
  value: number;
  targetValue?: number;
  valueToString: (value: number) => string;
  color?: ComponentColorOrDefault;
}

export function FilledGauge({
  min,
  max,
  value,
  targetValue = NaN,
  valueToString,
  color,
}: FilledGaugeProps) {
  const colorClassName = useComponentColorClassName(color, COLOR_CLASSES);

  const zeroAsPercent = calculateClampedPercent(0, min, max);

  const valueAsPercent = calculateClampedPercent(value, min, max);

  const valueFillFromPercent = Math.min(valueAsPercent, zeroAsPercent);
  const valueFillToPercent = Math.max(valueAsPercent, zeroAsPercent);

  const targetValueAsPercent =
    targetValue === undefined
      ? NaN
      : calculateClampedPercent(targetValue, min, max);

  const targetFillFromPercent = Math.min(targetValueAsPercent, zeroAsPercent);
  const targetFillToPercent = Math.max(targetValueAsPercent, zeroAsPercent);

  const fillBackgroundSize = (() => {
    if (
      valueFillToPercent > zeroAsPercent &&
      targetFillToPercent > valueFillToPercent
    ) {
      return `${targetFillToPercent - valueFillToPercent}%`;
    }

    if (
      valueFillFromPercent < zeroAsPercent &&
      targetFillFromPercent < valueFillFromPercent
    ) {
      return `${valueFillFromPercent - targetFillFromPercent}%`;
    }

    return undefined;
  })();

  return (
    <>
      {!Number.isNaN(value) && (
        <>
          <div
            className={cx('tw-flex-none')}
            style={{ flexGrow: valueFillFromPercent }}
          />

          <div
            className={cx(
              'tw-my-4',
              'tw-bg-metal-30',
              'dark:tw-bg-metal-80',
              'tw-rounded-6',
              'tw-w-4',
            )}
            style={{ flexGrow: valueFillToPercent - valueFillFromPercent }}
          />

          <div
            className={cx('tw-flex-none')}
            style={{ flexGrow: 100 - valueFillToPercent }}
          />
        </>
      )}

      {!Number.isNaN(targetValue) && (
        <div className={cx('tw-absolute', 'tw-inset-0', 'tw-flex')}>
          <div
            className={cx('tw-flex-none')}
            style={{ flexGrow: targetFillFromPercent }}
          />

          <div
            className={cx(
              'tw-my-4',
              'tw-border',
              targetValue < 0
                ? 'tw-bg-gradient-to-r'
                : 'tw-bg-gradient-to-l tw-bg-right',
              !fillBackgroundSize && 'tw-to-[--surface-elevated]',
              !fillBackgroundSize && 'dark:tw-to-[--surface-elevated]',
              'tw-bg-no-repeat',
              colorClassName,
              'tw-rounded-6',
              'tw-w-4',
            )}
            style={{
              flexGrow: targetFillToPercent - targetFillFromPercent,
              backgroundSize: fillBackgroundSize,
            }}
          />

          <div
            className={cx('tw-flex-none')}
            style={{ flexGrow: 100 - targetFillToPercent }}
          />
        </div>
      )}

      {Number.isNaN(targetValue) ? (
        <ValueLabel value={valueToString(value)} percent={50} color="Gray" />
      ) : (
        <>
          <ValueLabel
            value={valueToString(value)}
            percent={valueAsPercent}
            placement="TopMiddle"
            color="Gray"
          />

          <ValueLabel
            value={valueToString(targetValue)}
            percent={targetValueAsPercent}
            color={color}
            placement={
              targetValueAsPercent > zeroAsPercent
                ? 'BottomLeft'
                : 'BottomRight'
            }
          />
        </>
      )}
    </>
  );
}
