// @ts-ignore
import type * as RCL from 'rclnodejs';

import { info, namespace } from '@sb/log';
import type {
  JointArmTarget,
  ArmTarget,
  MotionPlanRequest,
} from '@sb/motion-planning';
import { getNode } from '@sb/ros/getNode';
import { getRCL } from '@sb/ros/getRCL';

const ns = namespace('TaskFromRobotGoalAction');

export type TaskFromRobotGoalActionRequest = {
  targets: MotionPlanRequest['targets'];
};

export class TaskFromRobotGoalAction {
  public static JOINT_NAMES = [
    'joint0',
    'joint1',
    'joint2',
    'joint3',
    'joint4',
    'joint5',
  ];

  public motionSucceeded: boolean;

  private request: TaskFromRobotGoalActionRequest;

  public constructor(request: TaskFromRobotGoalActionRequest) {
    this.request = request;
    this.motionSucceeded = false;
  }

  public async sendGoal() {
    const rcl = await getRCL();

    const client = new rcl.ActionClient(
      await getNode(),
      'standard_bots_msgs/action/TaskFromRobotGoal',
      '/make_task_from_robot_goal',
    );

    info(ns`sendGoal`, 'waiting for action server...');
    await client.waitForServer();

    info(ns`sendGoal`, 'creating goal');
    const goalMessage = await this.createGoal();

    info(ns`sendGoal`, 'sending goal request');

    const goalHandle = await client.sendGoal(goalMessage, (feedback: any) => {
      this.onFeedback(feedback);
    });

    if (!goalHandle.isAccepted()) {
      info(ns`sendGoal`, 'goal rejected');

      return;
    }

    info(ns`sendGoal`, 'goal accepted');

    await goalHandle.getResult();

    if (goalHandle.isSucceeded()) {
      info(ns`sendGoal`, 'motion completed successfully');
      this.motionSucceeded = true;
    } else {
      info(ns`sendGoal`, 'motion failed');
      this.motionSucceeded = false;
    }
  }

  private async createGoal() {
    const rcl = await getRCL();

    const TaskFromRobotGoal = rcl.require(
      'standard_bots_msgs/action/TaskFromRobotGoal',
    );

    const goalMessage = new TaskFromRobotGoal.Goal();

    info(ns`createGoal`, 'creating goal message');
    const target = this.request.targets[0];

    info(ns`createGoal`, 'target', target);

    if ('jointAngles' in target) {
      info(ns`createGoal`, 'creating joint space target');
      this.addJointStateGoal(rcl, goalMessage, target);
    } else if ('pose' in target) {
      info(ns`createGoal`, 'creating cartesian pose target');
      // this.setPoseConstraints(rcl, goalMessage, target);
    }

    this.setConnection(rcl, goalMessage, target);

    goalMessage.execute = true;

    info(ns`createGoal`, 'finished creating goal message');

    return goalMessage;
  }

  private addJointStateGoal(
    rcl: typeof RCL,
    goalMessage: any,
    target: JointArmTarget,
  ) {
    info(ns`createGoal`, 'setting joint space goal');

    const jointState = rcl.createMessageObject('sensor_msgs/msg/JointState');

    info(
      ns`setJointConstraint`,
      `setting names to ${TaskFromRobotGoalAction.JOINT_NAMES}`,
    );

    jointState.name = TaskFromRobotGoalAction.JOINT_NAMES;

    info(ns`setJointConstraint`, `setting positions to ${target.jointAngles}`);
    jointState.position = target.jointAngles;

    const RobotGoal = rcl.require('standard_bots_msgs/msg/RobotGoal');

    const robotGoal = rcl.createMessageObject(
      'standard_bots_msgs/msg/RobotGoal',
    );

    robotGoal.type = RobotGoal.TYPE_JOINT_STATE;
    robotGoal.goal_joint_state = jointState;

    // eslint-disable-next-line
    goalMessage.goals = [robotGoal];
  }

  private setConnection(rcl: typeof RCL, goalMessage: any, target: ArmTarget) {
    const Connection = rcl.require('standard_bots_msgs/msg/Connection');

    const connection = rcl.createMessageObject(
      'standard_bots_msgs/msg/Connection',
    );

    if (target.motionKind === 'line') {
      info(
        ns`createGoal`,
        `setting connection to TYPE_PILZ_LIN ${Connection.TYPE_PILZ_LIN}`,
      );

      connection.type = Connection.TYPE_PILZ_LIN;
    } else {
      info(
        ns`createGoal`,
        `setting connection to TYPE_OMPL ${Connection.TYPE_OMPL}`,
      );

      connection.type = Connection.TYPE_OMPL;
    }

    // eslint-disable-next-line
    goalMessage.connections = [connection];
  }

  private onFeedback(feedback: any) {
    info(ns`onFeedback`, `Received feedback`, feedback);
  }
}
