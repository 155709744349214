import cx from 'classnames';
import { useState } from 'react';

import type { IconKind } from '../../icons';
import { Icon } from '../../icons';
import type { PopperProps } from '../../utility';
import { Popper } from '../../utility';

interface SettingsGroupMenuButtonProps
  extends Omit<PopperProps, 'isOpen' | 'onClose' | 'onClick'> {
  iconKind?: IconKind;
}

export function SettingsGroupMenuButton({
  iconKind = 'ellipsisCircle',
  className,
  ...rest
}: SettingsGroupMenuButtonProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popper
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
      }}
      placement="bottom-end"
      offset={-10}
      skidding={12}
      className={cx(
        className,
        'tw-text-label-secondary',
        'tw-py-12',
        '-tw-my-12',
      )}
      {...rest}
    >
      <Icon kind={iconKind} />
    </Popper>
  );
}
