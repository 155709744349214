import * as zod from 'zod';

import { ExternalToRobotPort } from './ExternalToRobotPort';
import { RobotToExternalPort } from './RobotToExternalPort';

export const ExternalControlSettings = zod.object({
  isEnabled: zod.boolean().default(false),
  is24VIOEnabled: zod.boolean().default(false),
  ioPorts: zod
    .object({
      // inputs
      play: ExternalToRobotPort.optional(),
      pause: ExternalToRobotPort.optional(),
      loadDefaultRoutine: ExternalToRobotPort.optional(),
      // outputs
      ready: RobotToExternalPort.optional(),
      running: RobotToExternalPort.optional(),
      fault: RobotToExternalPort.optional(),
      defaultRoutineLoaded: RobotToExternalPort.optional(),
    })
    .default({}),
  defaultRoutineID: zod.string().nullable().default(null),
});

export type ExternalControlSettings = zod.infer<typeof ExternalControlSettings>;
