import cx from 'classnames';
import { useId } from 'react';

import { Switch } from '../form';
import type { SwitchProps } from '../form';

import { SettingsGroupItem } from './SettingsGroupItem';

export interface SettingsGroupSwitchItemProps extends SwitchProps {
  label?: React.ReactNode;
  secondaryLabel?: React.ReactNode;
  isSeparated?: boolean;
}

export function SettingsGroupSwitchItem({
  label,
  secondaryLabel,
  isSeparated,
  className,
  disabled,
  ...rest
}: SettingsGroupSwitchItemProps) {
  const id = useId();

  return (
    <SettingsGroupItem className={className} isSeparated={isSeparated}>
      <label
        className={cx('tw-flex-1', disabled && 'tw-text-label-quaternary')}
        htmlFor={id}
      >
        {label}
      </label>

      {secondaryLabel && (
        <span
          className={
            disabled ? 'tw-text-label-quaternary' : 'tw-text-label-secondary'
          }
        >
          {secondaryLabel}
        </span>
      )}

      <Switch id={id} disabled={disabled} {...rest} />
    </SettingsGroupItem>
  );
}
