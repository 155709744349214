import type { Equipment, Routine, Step } from '@sb/remote-control/types';
import type {
  Routine as RoutineRunnerSchema,
  RoutineStepConfiguration,
  SpeedProfile,
} from '@sb/routine-runner';

import { listAllSteps } from '../listAllSteps';

import { convertStepConfigurationToRoutineRunnerFormat } from './arguments';

/**
 * Combine the step configuration for a robot and the common step
 * configuration to generate a step configuration compatible with
 * the routine-runner schema.
 */
function convertStepsToRoutineRunnerStepConfiguration(
  steps: Step.ConvertedSummary[],
  stepConfigurations: Record<string, Step.ConvertedConfiguration>,
  baseSpeedProfile: SpeedProfile,
  robotEquipment: Equipment.ConvertedResponse[],
): [RoutineStepConfiguration, ...RoutineStepConfiguration[]] {
  const convertedSteps: RoutineStepConfiguration[] = steps.map((step) => {
    const configuration = stepConfigurations[step.id];

    const convertedStep: RoutineStepConfiguration =
      convertStepConfigurationToRoutineRunnerFormat(
        step,
        configuration,
        baseSpeedProfile,
        robotEquipment,
      );

    // The routine-runner doesn't support an empty array for the `steps` field.
    // So, we should include it only when we have steps.
    if (step.steps.length) {
      convertedStep.steps = convertStepsToRoutineRunnerStepConfiguration(
        step.steps,
        stepConfigurations,
        baseSpeedProfile,
        robotEquipment,
      );
    }

    return convertedStep;
  });

  return convertedSteps as [
    RoutineStepConfiguration,
    ...RoutineStepConfiguration[],
  ];
}

interface ConvertRoutineToRoutineRunnerSchemaArguments {
  routine: Routine.ConvertedResponse;
  baseSpeedProfile: SpeedProfile;
  robotEquipment: Equipment.ConvertedResponse[];
}

/**
 * A routine contains a list of steps and the configuration for each step.
 *
 * When loading a routine into the routine-runner, we need to combine the
 * step data with the configuration for each step.
 */
export const convertRoutineToRoutineRunnerSchema = (
  args: ConvertRoutineToRoutineRunnerSchemaArguments,
): RoutineRunnerSchema => {
  const {
    id,
    name,
    mainLoopStepID,
    steps,
    stepConfigurations,
    space,
    environmentVariables,
  } = args.routine;

  const routineRunnerSteps = convertStepsToRoutineRunnerStepConfiguration(
    steps,
    stepConfigurations,
    args.baseSpeedProfile,
    args.robotEquipment,
  );

  // find the outermost loop step if main loop hasn't been manually assigned
  const mainLoop =
    mainLoopStepID ??
    listAllSteps(steps).find((step) => step.stepKind === 'Loop')?.id;

  return {
    id,
    name,
    mainLoop,
    steps: routineRunnerSteps,
    environmentVariables,
    space,
  };
};
