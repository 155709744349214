interface UploadFileToStorageArguments {
  file: Blob;
}

interface UploadFileToStorageResponse {
  id: string;
  downloadURL: string;
}

export async function uploadFileToStorage({
  file,
}: UploadFileToStorageArguments): Promise<UploadFileToStorageResponse> {
  const response = await fetch('/storage', {
    method: 'post',
    headers: { 'Content-Type': file.type || 'application/octet-stream' },
    body: file,
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const responseData = await response.json();

  const id = responseData?.id;

  if (typeof id !== 'string') {
    throw new Error('storage id missing in response');
  }

  return {
    id,
    downloadURL: `/storage/${id}`,
  };
}
