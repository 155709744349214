import { cloneDeep } from 'lodash';
import * as zod from 'zod';

import {
  BlendConfig,
  DEFAULT_TCP_OFFSET_OPTION,
  MotionKind,
  TCPOffsetOption,
  forwardKinematics,
} from '@sb/motion-planning';
import { ArmPosition, Expression } from '@sb/routine-runner';

import { MotionSpeed } from './motionSpeed';

/**
 * Types matching the routine runner schema for "MoveArmTo" steps.
 */
export namespace MoveArmToTargetStep {
  /**
   * Where the shape of the step configuration changes,
   * this function migrates the configuration data to the new shape
   */

  const argumentsMigration = (argsIn: any) => {
    let args = argsIn;

    if (args?.argumentKind === 'MoveArmTo') {
      if (args.target) {
        args = cloneDeep(args);

        if (args.target.motionKind) {
          if (!args.motionKind) {
            args.motionKind = args.target.motionKind;
          }

          args.target.motionKind = undefined;
        }

        if (!args.target.jointAngles) {
          args.target = null;
        } else if (!args.target.pose) {
          args.target.pose = forwardKinematics(args.target.jointAngles);
        }
      }

      if (args.shouldMatchJointAngles === undefined) {
        args = { ...args };
        args.shouldMatchJointAngles = args.motionKind === 'joint';
      }
    }

    return args;
  };

  export const Arguments = zod.preprocess(
    argumentsMigration,
    zod.object({
      argumentKind: zod.literal('MoveArmTo'),
      expression: Expression.optional(),
      motionKind: MotionKind.default('joint'),
      shouldMatchJointAngles: zod.boolean().default(true),
      tcpOffsetOption: TCPOffsetOption.default(DEFAULT_TCP_OFFSET_OPTION),
      motionSpeed: MotionSpeed.optional(),
      positionListID: zod.string().nullable().default(null),
      positionListIndex: zod.number().optional(),
      target: ArmPosition.nullable().default(null),
      targetKind: zod
        .union([
          zod.literal('singlePosition'),
          zod.literal('positionList'),
          zod.literal('expression'),
        ])
        .default('singlePosition'),
      isWaypoint: zod.boolean().default(false),
      isCacheable: zod.boolean().default(false),
      blend: BlendConfig.optional(),
      reduceSmoothing: zod.boolean().default(false),
    }),
  );

  export type Arguments = zod.infer<typeof Arguments>;
}
